/* GLOBAL STYLES */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

@font-face {
  font-family: "HelveticaNeueRegular";
  src: url("../fonts/HelveticaNeueRegular.eot");
  src: url("../fonts/HelveticaNeueRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/HelveticaNeueRegular.woff2") format("woff2"),
    url("../fonts/HelveticaNeueRegular.woff") format("woff"),
    url("../fonts/HelveticaNeueRegular.ttf") format("truetype"),
    url("../fonts/HelveticaNeueRegular.svg#HelveticaNeueRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-size: 16px;
  color: #707070;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
a {
  color: #001689;
  text-decoration: none;
  transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
}
a:focus {
  outline: none;
  text-decoration: none;
  color: #001689;
}
a:hover,
a:active {
  outline: 0;
  text-decoration: none;
  color: #001689;
}

p {
  margin: 0 0 20px 0;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
hr {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  height: 1px;
  margin-bottom: 23px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: 700;
  color: #707070;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  margin: 36px 0 12px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 42px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}

img {
  max-width: 100%;
  border: 0;
}
/*input:not([type="radio"]):not([type="checkbox"]){-webkit-appearance:none; -webkit-border-radius:0px;}*/
.form-group {
  position: relative;
}
input,
button,
textarea,
select {
  border: 1px solid #d0d0d0;
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #707070;
  width: 100%;
  border-radius: 8px;
}
input[type="submit"],
button {
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: #e6e6e6;
  opacity: 0.82;
  z-index: 1;
}

.form-group input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
.form-group input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}
.form-group input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid #6f6f6f;
  left: 0;
}
.form-group input[type="checkbox"]:checked + label:after {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #6699ff;
  position: absolute;
  left: 2px;
  font-size: 14px;
  top: 1px;
}

.form-group label {
  font-size: 18px;
  color: #707070;
}
.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="tel"],
.form-group input[type="email"],
.form-group textarea {
  border-radius: 9px;
  border: 1px solid #d0d0d0;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;
}

input::placeholder {
  color: #707070;
  font-size: 16px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

::-webkit-selection {
  background-color: #6699ff;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background-color: #6699ff;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #6699ff;
  color: #fff;
  text-shadow: none;
}

/*::-webkit-input-placeholder { color: #858585; opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";}
::-moz-placeholder { color: #858585; opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";}
:-ms-input-placeholder { color: #858585; opacity: 1; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";}*/

.mb-20 {
  margin-bottom: 20px;
}
.main-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-----Common Css End Here-------*/

/*---Header---*/
.logo {
  padding-top: 10px;
  padding-bottom: 10px;
}

/*Nav*/
.nav-menu {
  float: right;
}

.menulinks {
  display: none;
  text-align: center;
  width: 41px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  font-size: 22px;
  position: absolute;
  top: 0px;
  right: 15px;
  cursor: pointer;
  z-index: 1;
}
ul.mainmenu {
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}
ul.mainmenu > li {
  display: inline-block;
  padding: 0 0;
  position: relative;
}
ul.mainmenu > li > a:link,
ul.mainmenu > li > a:visited {
  padding: 20px 0;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #707070;
  line-height: 24px;
  letter-spacing: normal;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  position: relative;
}
ul.mainmenu > li:hover a,
ul.mainmenu > li > a.current {
  color: #001689;
}

ul.mainmenu ul {
  position: absolute;
  left: 0;
  width: 263px;
  top: 60px;
  padding: 0;
  list-style: none;
  margin: 0;
  z-index: 3;
  display: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  background-color: #ffffff;
  padding: 10px 15px;
}
ul.mainmenu ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  margin-right: 0;
  margin-bottom: 10px;
  padding-right: 0;
}
ul.mainmenu ul li:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #69f;
  top: 48%;
  left: 0;
}
ul.mainmenu ul li:last-child {
  border-bottom: none;
}
ul.mainmenu li li a:link,
ul.mainmenu li li a:visited {
  color: #707070;
  display: block;
  padding: 8px 15px;
  font-size: 16px;
}
ul.mainmenu li li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
ul.mainmenu ul ul {
  position: absolute;
  left: 100%;
  top: 0;
  display: none;
}
/*Nav*/

/*Tables css*/
/*table {border-collapse: collapse; margin: 0 0 1.5em; width: 100%; } 
thead th {border-bottom: 2px solid #bbb; padding-bottom: 0.5em; } 
th {padding: 0.4em; text-align: left; } 
tr {border-bottom: 1px solid #eee; } 
td {padding: 0.4em; } 
th:first-child, 
td:first-child {padding-left: 0; } 
th:last-child, 
td:last-child {padding-right: 0; }*/
.btn {
  background-image: linear-gradient(to right, #001689 0%, #006dff 100%);
  color: #fff;
  padding: 5px 18px;
  border-radius: 19px;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #006dff;
}
.btn:hover {
  background: transparent;
  color: #006dff;
}

.link a:hover {
  text-decoration: underline;
}
.hover-buttons .white-btn {
  margin-bottom: 10px;
}
.white-btn {
  background: #fff;
  color: #6699ff;
  border-radius: 19px;
  border: 1px solid #7099f8;
  padding: 5px 10px;
  width: 158px;
  margin-bottom: 10px;
}
.white-btn:hover {
  background: #6699ff;
  color: #fff;
}
.blue-btn {
  background-color: #001689;
  color: #fff;
  border-radius: 19px;
  border: 1px solid #001689;
  padding: 5px 8px;
  width: 158px;
}
.blue-btn:hover {
  background-image: linear-gradient(to bottom, #006dff 0%, #001689 100%);
  color: #fff;
}
.view-more {
  color: #6699ff;
  font-weight: 900;
  cursor: pointer;
}
.view-more a {
  color: #6699ff;
}
.load-more {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.btn.blue-square-btn {
  border-radius: 10px;
  padding: 11px 20px;
  cursor: pointer;
}

.toggle-content.show,
.right-menu ul .my-account-toggle.show {
  display: block;
}
.right-menu ul .my-account img,
.right-menu ul .my-account i {
  pointer-events: none;
}

/*-Container-*/
.container {
  max-width: 1110px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
.section {
  position: relative;
  width: 100%;
  z-index: 1;
}

/*---Custom Start---*/
.header-main {
  background-color: #f4f4f4;
}
.header-main .header-section {
  max-width: 1110px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-main .menu-section {
  position: relative;
  /* z-index: 3; */
  /* z-index: 1; */
}
.header-main .menu-section .nav-menu {
  display: flex;
  align-items: center;
}
ul.mainmenu li {
  padding-right: 30px;
}
ul.mainmenu li:last-child {
  padding-right: 0;
}
ul.mainmenu ul li:last-child {
  margin-bottom: 0;
}

ul.mainmenu > li.has-sub-menu > a:before {
  content: "\f078";
  font-family: "FontAwesome";
  position: absolute;
  right: -19px;
  top: 32%;
  font-size: 14px;
}
ul.mainmenu > li.has-sub-menu a {
  position: relative;
}
ul.mainmenu > li.has-sub-menu {
  margin-right: 14px;
}

.right-menu {
  position: relative;
  margin-left: 22px;
}
.right-menu ul {
  display: flex;
  align-items: center;
}
.right-menu ul .my-account {
  margin-left: 32px;
  position: relative;
  cursor: pointer;
}
.right-menu ul .my-account i {
  top: -5px;
  position: relative;
  margin-left: 10px;
}

.right-menu ul .my-account-toggle {
  position: absolute;
  top: 40px;
  left: -40px;
  background: #fff;
  width: 150px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 9px 10px;
  display: none;
  /* z-index: 9; */
  z-index: 2;
}
.my-account-toggle li a {
  color: #707070;
}
.my-account-toggle li:hover,
.my-account-toggle li a:hover {
  color: #006dff;
}
.right-menu ul .my-account i .my-account-toggle i {
  margin-left: 0;
}
.right-menu ul .my-account ul.my-account-toggle i {
  margin-left: 0;
  top: 0;
  margin-right: 5px;
}
/* m-css 16-8 */
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  background: rgba(255, 255, 255, 0.3);
}
/* m-css 16-8 */

/**---Banner Section---**/
.banner-section {
  padding: 20px 0 40px;
}
.banner-section .welcome-box-wrap {
  max-width: 277px;
  flex: 0 0 277px;
}
.banner-slider .slick-list {
  border-radius: 16px;
}
.banner-section .banner-slider-wrap {
  max-width: calc(100% - 277px);
  flex: 0 0 calc(100% - 277px);
}
.banner-section .banner-slider-wrap .banner-slider-box img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 16px;
}
.banner-section .welcome-box-wrap .welcome-box h4 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.banner-section .welcome-box-wrap .welcome-box {
  padding: 30px;
  border-radius: 16px;
  background-color: #e21c2a;
  color: #fff;
  text-align: center;
}
.banner-section .welcome-box-wrap .welcome-box p {
  font-size: 20px;
  margin-bottom: 0;
}
.banner-section .welcome-box-wrap .welcome-box img {
  margin: 33px 0 50px;
  max-height: 66px;
  object-fit: contain;
}
.banner-section .welcome-box-wrap .welcome-box .link a {
  text-decoration: underline;
  color: #fff;
  font-size: 20px;
}
.banner-section .banner-slider {
  height: 285px;
  border-radius: 18px;
  background-position: center center;
  background-size: cover;
}

/**---Product Section ---**/
.product-section {
  background-color: #f4f4f4;
  padding: 20px 0;
}
.product-topbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* display: block; */
}

.product-detail-sec .product-topbar .breadcrumb {
  max-width: 127px;
}
.breadcrumb ul.breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumb ul.breadcrumb-list li {
  font-weight: 500;
  color: #6f6f6f;
  margin-right: 30px;
  position: relative;
  margin-bottom: 10px;
}
.breadcrumb ul.breadcrumb-list li:last-child {
  margin-right: 0;
}
.breadcrumb ul.breadcrumb-list li:before {
  content: "\f054";
  font-family: "FontAwesome";
  position: absolute;
  right: -20px;
  top: 4px;
  font-size: 12px;
}
.breadcrumb ul.breadcrumb-list li:last-child:before {
  content: none;
}
.cart,
.search-form,
.search-company,
.search-filter-wrap .filter-form,
.filter-wrap .filter-form {
  cursor: pointer;
}
.product-topbar .product-searchbar {
  display: flex;
  align-items: center;
  margin-left: 37px;
  max-width: calc(100% - 507px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 507px);
}
.product-topbar .breadcrumb {
  padding-left: 40px;
  position: relative;
  max-width: 507px;
  flex: 0 0 507px;
}
.product-detail-sec .product-topbar .product-searchbar {
  justify-content: flex-end;
}
/* .product-detail-sec .product-topbar .product-searchbar{
  justify-content: flex-end;
} */
.product-topbar .product-searchbar .link {
  margin-right: 20px;
}
.product-topbar .product-searchbar .link a {
  font-size: 18px;
  padding: 2px 32px;
}
.search-form .form-group input {
  border-radius: 16px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  padding: 5px 21px 5px 30px;
}
.search-form .form-group input {
  margin-bottom: 0;
}
.search-form .form-group input::placeholder {
  color: #b1b1b1;
}
.search-form .form-group .search-icon {
  position: absolute;
  left: 10px;
  top: 6px;
  font-size: 13px;
}
.search-form .form-group {
  position: relative;
  margin-right: 20px;
}

.product-section .product-list-main {
  max-width: 277px;
  flex: 0 0 277px;
}
.product-section .product-list-main .categories-wrap .main-title h4 {
  color: #001689;
  font-size: 20px;
  margin: 0 0 20px;
  padding: 0 40px;
}
.product-section .product-list-main .categories h5 {
  margin: 0 0 20px;
  color: #707070;
}
.product-section .product-list-main .categories-wrap {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 20px 10px 20px 0;
  margin-top: 20px;
}
.product-section .product-list-main .categories-list {
  position: relative;
  max-height: 70vh;
  overflow-y: auto;
}
.product-section .product-list-main .categories .form-group {
  margin-bottom: 20px;
}
.product-section .product-list-main .categories .form-group:last-child {
  margin-bottom: 0;
}
.product-section .product-list-main .categories-list .categories {
  padding-bottom: 20px;
  border-bottom: 1px solid #fafaf8;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-right: 30px;
}

/* width */
.product-section .product-list-main .categories-list::-webkit-scrollbar,
.global-consumption-table-sec .global-consumption-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.product-section .product-list-main .categories-list::-webkit-scrollbar-track,
.global-consumption-table-sec
  .global-consumption-table::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}

/* Handle */
.product-section .product-list-main .categories-list::-webkit-scrollbar-thumb,
.global-consumption-table-sec
  .global-consumption-table::-webkit-scrollbar-thumb {
  background: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
.product-section
  .product-list-main
  .categories-list::-webkit-scrollbar-thumb:hover,
.global-consumption-table-sec
  .global-consumption-table::-webkit-scrollbar-thumb:hover {
  background: #6699ff;
}

.product-section .product-list-wrap {
  max-width: calc(100% - 277px);
  flex: 0 0 calc(100% - 277px);
}
.product-list {
  border-radius: 15px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 20px;
  padding: 30px 20px;
  /* padding: 30px 40px 30px 20px;  commented for home issue*/
  margin-bottom: 50px;
}
.product-list .products {
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  position: relative;
}
.product-list .products .product-item {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding: 0 12px;
  margin-bottom: 40px;
}
.product-list .products .item-box {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  background: #fff;
  position: relative;
  display: inline-flex;
  width: 100%;
}
.product-list .products .item-box .image {
  display: inline-flex;
  width: 100%;
  height: 238px;
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.product-list .products .item-box img {
  /* border-radius: 10px;
  max-height: 238px; */
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-position: top;
}
.product-list .products .stage:before,
.product-section .product-list-wrap .stage:before {
  content: none;
}
.product-detail .product-name {
  color: #707070;
  line-height: 25px;
}
.product-detail .product-price {
  color: #707070;
  font-weight: 600;
  text-transform: uppercase;
  /* padding-left: 10px; commented for home issue*/
}
.product-detail {
  margin-top: 10px;
}
.products .product-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.products .product-item .item-box {
  /* margin: 0 10px; commented for home issue*/
}

.products .product-item .item-box img {
  border-radius: 11px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  -o-border-radius: 11px;
}
/**--Product Hover Box--**/
.product-list .products .product-item .item-box:hover .product-hover-box {
  opacity: 1;
  visibility: visible;
}
.product-hover-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease-in-out;
  text-align: center;
}
.product-hover-box:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  background-color: #6699ff;
  opacity: 0.69;
}
.product-hover-box .desc {
  font-size: 12px;
  color: #fff;
}
.product-hover-box .product-hover-box-wrap {
  padding: 0 20px;
  z-index: 1;
  width: 100%;
}
.product-hover-box .hover-buttons {
  margin-bottom: 10px;
}

/*--User Footer--*/
.user-footer.footer {
  position: relative;
  padding: 40px 0 20px;
  background-color: #b7b7b7;
}
.user-footer.footer .footer-logo {
  max-width: 242px;
  flex: 0 0 242px;
}
.user-footer.footer .footer-links {
  max-width: calc(100% - 242px);
  flex: 0 0 calc(100% - 242px);
  display: flex;
  justify-content: space-between;
  padding-left: 70px;
  margin-bottom: 28px;
}
.user-footer.footer .footer-links .quick-links h4 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 13px;
}
.user-footer.footer .footer-links .quick-links a {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: 30px;
}
.user-footer.footer .footer-links .quick-links li {
  margin-bottom: 6px;
}
.user-footer.footer .footer-links .quick-links a:hover {
  color: #001689;
}
.user-footer.footer .footer-links .quick-links li:last-child {
  margin-bottom: 0;
}

/* end */
.admin-footer.footer {
  padding-top: 40px;
  width: 100%;
  bottom: 0;
  /* position: fixed; */
  position: relative;
  left: 0;
  /* z-index: 1; */
  background: #fff;
}
.admin-footer.footer.sticky {
  position: fixed;
}

.admin-footer .footer-logo {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #b1b1b1;
}
/* Admin Footer */

.disclaimer {
  max-width: 963px;
  padding-top: 10px;
  margin: 0 auto;
  border-top: 1px solid #ffffff;
  text-align: center;
}
.disclaimer ul.privacy {
  display: flex;
  justify-content: center;
}
.disclaimer ul.privacy li {
  margin-right: 30px;
  position: relative;
}
.disclaimer ul.privacy li:last-child {
  margin-right: 0;
}
.disclaimer ul.privacy li:before {
  content: "";
  position: absolute;
  right: -16px;
  width: 2px;
  height: 100%;
  background: #fff;
}
.disclaimer ul.privacy li:last-child:before {
  content: none;
}
.disclaimer ul.privacy li a {
  color: #fff;
  font-size: 18px;
}

/*--Login Page--*/
.login-content-sec .login-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.login-content-sec .login-content {
  border: 1px solid #707070;
  background-image: linear-gradient(180deg, #011a83 0%, #006bfc 100%);
  max-width: 50%;
  flex: 0 0 50%;
  width: 100%;
  padding: 15px 100px 80px 100px;
  height: 100vh;
}
.login-content-sec .login-content .login-title-wrap {
  padding-top: 140px;
  max-width: 416px;
}
.login-content-sec .login-content .login-title-wrap .login-title {
  color: #fff;
}
.login-content-sec .login-content .login-content-wrap .login-logo img {
  width: 184px;
}
.login-content-sec .login-content .login-title-wrap .login-title h4 {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}
.login-content-sec
  .login-content
  .login-title-wrap
  .login-title
  h4
  span.gital-color {
  color: #2aa3da;
}
.login-content-sec
  .login-content
  .login-title-wrap
  .login-title
  .login-subtitle {
  font-size: 25px;
  font-weight: 300;
}
.social-icons {
  position: absolute;
  display: flex;
  bottom: 0;
}
.social-icons ul {
  display: flex;
}
.social-icons ul li a i {
  height: 36px;
  width: 36px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.social-icons ul li {
  margin-right: 15px;
}
.social-icons ul li:last-child {
  margin-right: 0;
}

.login-content-sec .login-form {
  max-width: 50%;
  flex: 0 0 50%;
  width: 100%;
  position: relative;
  background-color: #f4f4f4;
  padding: 80px 100px 80px 0;
}
.login-content-sec .login-content .login-content-wrap {
  position: relative;
  height: 100%;
}
.login-content-sec .login-form .login-form-main {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.login-content-sec .login-form .login-form-main {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-top-right-radius: 26px;
  background-color: #ffffff;
  border-bottom-right-radius: 26px;
  padding: 100px 65px 100px;
  width: 100%;
}
.login-content-sec .login-form .login-form-wrap .form-title h5 {
  margin: 0 0 65px 0;
  color: #001689;
  font-size: 18px;
  font-weight: 900;
}
.login-content-sec .login-form .login-form-wrap .login-form-inner {
  position: relative; /*max-width: 426px;*/
}
.login-content-sec .login-form .login-form-wrap .login-form-inner .forgot-pwd {
  position: absolute;
  right: 0;
}
.login-content-sec
  .login-form
  .login-form-wrap
  .login-form-inner
  .forgot-pwd
  a {
  color: #6699ff;
  font-size: 16px;
  font-weight: 300;
}
.login-content-sec .login-submit {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 68px;
}
.login-content-sec .login-submit .blue-btn {
  font-size: 18px;
  font-weight: 700;
  width: 317px;
  height: 49px;
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 24px;
  margin-bottom: 10px;
}
.login-content-sec .login-submit .link a {
  color: #001689;
  font-size: 16px;
  font-weight: 300;
}
.login-form-inner .link a:hover {
  text-decoration: underline;
}
.login-content-sec .login-form .form-group input {
  margin-top: 10px;
}

/**-listing orders-**/
.inner-banner-sec {
  padding-top: 32px;
}
.inner-banner-sec .inner-banner {
  position: relative;
  width: 100%;
  height: 240px;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.inner-banner-sec .inner-banner .inner-banner-title h4 {
  margin: 0;
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  padding-right: 62px;
  max-width: 430px;
  text-align: right;
}
.search-filter .search-filter-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-filter {
  position: relative;
  padding: 30px 0;
}
.search-filter .search-filter-wrap .search-filter-title {
  font-size: 18px;
  color: #707070;
  margin-right: 10px;
}
.search-filter .search-filter-wrap .cart {
  margin-left: 24px;
}
.listing-order-wrap {
  background: #fff;
  padding: 45px 65px;
  border-radius: 26px;
}
.listing-order-section {
  background-color: #f4f4f4;
  padding: 50px 0 57px 0;
}
.listing-order-section .stage {
  position: relative;
}

.listing-order-wrap .row {
  margin: 0 -23px;
}
.listing-order-wrap .listing-order-item {
  padding-right: 23px;
  padding-left: 23px;
  max-width: 33.33%;
  flex: 0 0 33.33%;
}
.listing-order-wrap .listing-order-item .listing-order-item-box {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 11px;
  background-color: #ffffff;
  margin-bottom: 30px;
}
.listing-order-wrap .listing-order-item .listing-order-item-box .image {
  height: 131px;
}
.listing-order-wrap .listing-order-item .listing-order-item-box .image img {
  max-height: 131px;
  object-fit: cover;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.listing-order-wrap .listing-order-item-hover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 1;
  color: #fff;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.listing-order-wrap .listing-order-item-image {
  position: relative;
}
.listing-order-wrap .listing-order-item-hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #6699ff;
  opacity: 0.7;
  z-index: -1;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.listing-order-wrap .listing-order-item-content {
  padding: 20px 25px;
}
.listing-order-wrap .listing-order-item-content h5 {
  color: #6f6f6f;
  font-weight: 900;
  margin: 0 0 12px 0;
}
.listing-order-wrap .listing-order-item-content .sub-title h6 {
  margin: 0;
  font-weight: 400;
  margin-bottom: 1px;
}
.listing-order-wrap .listing-order-item-content ul li {
  line-height: 21px;
}
.listing-order-wrap .listing-order-item-content ul {
  margin-bottom: 20px;
}
.listing-order-wrap .listing-order-item-content .view-ordr a {
  color: #6699ff;
  font-weight: 900;
}
.listing-order-wrap .item-code {
  font-size: 20px;
  font-weight: 900;
}
.listing-order-wrap .listing-order-item-hover .wrap {
  text-align: center;
}
.listing-order-wrap .load-more {
  margin-top: 20px;
}

.filter-form .filter-tab {
  width: 219px;
  border-radius: 16px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  padding: 4px 20px;
  position: relative;
  cursor: pointer;
}
.filter-form .filter-tab i {
  position: absolute;
  right: 6px;
  font-size: 13px;
  top: 6px;
}
.filter-listwrap {
  position: absolute;
  padding: 20px 20px;
  top: 40px;
  left: 0;
  z-index: 2;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  display: none;
}
.filter-form {
  position: relative;
}
.filter-listwrap .filter-list {
  position: relative;
  margin-bottom: 4px;
}
.filter-listwrap .filter-list h6 {
  margin: 0;
  color: #707070;
  margin-bottom: 3px;
}
.filter-listwrap .filter-list:last-child {
  margin-bottom: 0;
}

/**--consumption_report--**/
.report-topbar {
  background-color: #e21c2a;
  padding: 10px 0;
}
.report-topbar .title-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.report-topbar .title-wrapper h4 {
  color: #fff;
  margin: 0;
  font-size: 20px;
  margin-right: 33px;
}
.report-topbar .title-wrapper .image img {
  width: 118px;
}

.global-consumption-sec {
  padding: 40px 0 62px;
}
.global-consumption-sec .global-consumption-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.global-consumption-sec .global-consumption-wrap .title h4 {
  margin: 0;
  color: #6699ff;
  font-size: 18px;
  font-weight: 900;
}
.global-consumption-sec .global-consumption-wrap .last-update-date {
  font-family: Montserrat;
  font-size: 18px;
}
.global-consumption-sec .global-consumption-wrap .last-update-date strong {
  font-weight: 900;
}
.global-consumption-sec .global-consumption-graph {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 11px;
  background-color: #ffffff;
  padding: 28px 26px;
}
.global-consumption-table-sec {
  background-color: #f4f4f4;
  padding: 76px 0 60px;
}
.global-consumption-table-sec .global-consumption-table {
  background-color: #d6d6d6;
  /* padding-right: 9px;
  padding-bottom: 9px; */
  position: relative;
}
.global-consumption-table-sec
  .global-consumption-table
  .global-consumption-tablewrap {
  background: #fff;
  padding-left: 0;
  /* padding-right: 8px; */
  overflow: auto;
  /* padding-bottom: 30px; */
  /* padding-bottom: 10px; */
  height: 710px;
  /* max-height: 710px; */
}
.global-consumption-table table {
  background: #fff;
}
.global-consumption-table table tr th {
  color: #6699ff;
  font-size: 18px;
  font-weight: 400;
  padding: 23px 15px;
}
.global-consumption-table table td {
  padding: 23px 15px;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
  font-size: 18px;
}
.global-consumption-table table tr th,
.global-consumption-table table tr td {
  width: calc(100% / 6);
}

.global-consumption-table table.invoice-report-table tr th,
.global-consumption-table table.invoice-report-table tr td {
  width: auto;
}
.global-consumption-table table.invoice-report-table tr th {
  text-align: left;
}
.global-consumption-table table.invoice-report-table tr td:last-child {
  text-align: right;
}
/* verticel scrollbar spacing */
.global-consumption-table table tr td:last-child {
  /* border-right: 10px solid #d6d6d6; */
}
/* width */
/* .global-consumption-table-sec
  .global-consumption-table
  .global-consumption-tablewrap::-webkit-scrollbar {
  width: 18px;
  height: 18px;
} */

/* Track */
/* .global-consumption-table-sec
  .global-consumption-table
  .global-consumption-tablewrap::-webkit-scrollbar-track {
  background: #d6d6d6;
  border-radius: 5px;
} */

/* Handle */
/* .global-consumption-table-sec
  .global-consumption-table
  .global-consumption-tablewrap::-webkit-scrollbar-thumb {
  background: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
} */

/* Handle on hover */
/* .global-consumption-table-sec
  .global-consumption-table
  .global-consumption-tablewrap::-webkit-scrollbar-thumb:hover {
  background: #6699ff;
} */

.global-consumption-table-inner {
  background: #fff;
}

.download-excel {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  background-image: linear-gradient(to right, #011a83 0%, #006bfc 100%);
  width: 317px;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  font-weight: 700;
  font-size: 18px;
}
.download-excelwrap {
  padding-top: 60px;
  display: flex;
  justify-content: flex-end;
}

/*---*/

.gdi-solution-banner .inner-banner-wrap .inner-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-image: linear-gradient(180deg, #001689 0%, #006dff 100%);
  opacity: 0.76;
  z-index: -1;
}
.gdi-solution-banner .inner-banner-wrap {
  position: relative;
  z-index: 1;
}
.gdi-solution-banner .inner-banner-wrap .inner-banner {
  z-index: 1;
  justify-content: center;
}
.gdi-solution-banner .inner-banner-wrap .inner-banner h4 {
  max-width: 500px;
  padding-right: 0;
  text-align: center;
}
.gdi-solution-banner {
  padding-bottom: 35px;
}
.gdi-solution-sec {
  background: #f4f4f4;
  padding: 50px 0 57px;
}
.gdi-solutionwrap {
  border-radius: 26px;
  background-color: #ffffff;
  padding: 50px 65px 33px;
}
.gdi-solutionwrap .title {
  text-align: center;
}
.gdi-solutionwrap .title h4 {
  color: #001689;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 23px;
}
.gdi-solutionwrap .title .subtitle {
  font-size: 18px;
}
.gdi-option-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 27px;
  margin: 68px -40px 0 -40px;
  position: relative;
  padding-bottom: 40px;
}
.gdi-option-wrap .gdi-option-grid {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 40px;
}
.gdi-option-wrap .gdi-option-grid .title h4 {
  color: #6699ff;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 40px;
}
.option-box {
  box-shadow: 0 0 6px rgba(172, 172, 172, 0.5);
  border-radius: 11px;
  background-color: #ffffff;
}
.option-box .option-box-inner h6 {
  margin: 0;
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 4px;
}
.option-box .option-box-inner p {
  font-size: 18px;
}
.option-box .option-box-inner .note {
  color: #6f6f6f;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 900;
  margin-top: 15px;
}
.option-box .option-box-inner {
  padding: 30px;
  border-bottom: 1px solid #d6d6d6;
}
.option-box .fill-form {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 107px;
}
.option-box .fill-form .icon {
  font-size: 36px;
  color: #6699ff;
  cursor: pointer;
}
.option-box .fill-form .link a {
  color: #6699ff;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
}
.gdi-option-wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #dbdbdb;
}
.option-box .option-box-inner p:last-child {
  margin-bottom: 0;
}
/* m-css admin footer */
.admin-footer-margin {
  /* margin-bottom: 150px; */
}
/*---*/
.contact-sec.login-content-sec .login-content {
  max-width: 540px;
  flex: 0 0 540px;
  height: auto;
  padding: 149px 50px 50px 100px;
}
.contact-sec.login-content-sec .login-form {
  max-width: calc(100% - 540px);
  flex: 0 0 calc(100% - 540px);
  background: #fff;
  padding: 140px 100px 100px 100px;
  min-height: calc(100vh - 345px);
}
.contact-sec.login-content-sec .login-form .login-form-main {
  box-shadow: none;
  padding: 0;
  justify-content: flex-start;
}
.contact-sec.login-content-sec .login-content .login-title-wrap {
  padding-top: 0;
  max-width: none;
}
.contact-sec.login-content-sec
  .login-content
  .login-title-wrap
  .login-title
  .login-subtitle {
  font-size: 20px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
}
.contact-sec.login-content-sec
  .login-content
  .login-title-wrap
  .login-title
  h4 {
  margin-bottom: 50px;
  margin-top: 0;
}
.contact-form input,
.contact-form select,
.contact-form textarea {
  border: 1px solid #999999 !important;
  font-family: "Raleway", sans-serif;
  color: #999999;
}
.contact-form textarea {
  padding: 10px 20px;
  margin-top: 10px;
  height: 249px;
}
.contact-form label {
  font-family: "Raleway", sans-serif;
}
.contact-form .width-50 {
  max-width: 50%;
  flex: 0 0 50%;
}
.submit-btn button {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  background-image: linear-gradient(to right, #011a83 0%, #006bfc 100%);
  color: #fff;
  width: 226px;
  padding: 13px 10px;
  font-weight: 700;
}
.submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.submit-btn button:hover {
  background-image: linear-gradient(to right, #011a83 0%, #006bfc 100%);
}
/*---*/
.forgot-pwd.login-content-sec .login-form .login-form-wrap .form-title h5 {
  max-width: 278px;
  margin-bottom: 44px;
}
.forgot-pwd.login-content-sec .login-submit .blue-btn {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 24px;
  margin-bottom: 15px;
}
.forgot-pwd.login-content-sec
  .login-form
  .login-form-wrap
  .form-title.forgot-success-fail-msg
  h5 {
  max-width: 100%;
  text-align: center;
}
/* contact form m-css */
.contact-sec .login-form-wrap h4 {
  color: #001689;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-size: 19px;
  font-weight: 900;
  padding-bottom: 25px;
}
.contact-sec.login-content-sec .login-form .login-form-main {
  justify-content: center;
  text-align: center;
}
.contact-submit-btn {
  border-radius: 10px;
  background-image: linear-gradient(to right, #011a83 0%, #006bfc 100%);
  color: #fff;
  width: 266px;
  padding: 13px 10px;
  font-weight: 700;
}

/*-GDI solution form-*/
.gdi-solutionform .form-group label {
  color: #3e4c59;
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 400;
}
.gdi-solutionform .form-group input,
.gdi-solutionform .form-group textarea,
.gdi-solutionform .form-group select {
  color: #999999;
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  border-color: #999;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.gdi-solutionform .form-group {
  margin-bottom: 20px;
}
.gdi-solutionform .form-group input::placeholder,
.gdi-solutionform .form-group textarea::placeholder {
  color: #999999;
  font-size: 18px;
}
.success-msg.option-box .option-box-inner p {
  font-size: 17px;
}

/*--Product Details--*/
.product-detail-sec.product-section {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 69px;
}
.product-detail-sec.product-section .product-topbar {
  padding-bottom: 20px;
  border-bottom: 2px solid #f5f5f5;
}
.product-detail-sec.product-section .product-topbar .breadcrumb {
  padding-left: 0;
}

/* css custom by dev */
.product-detail-sec.product-section
  .product-topbar
  .breadcrumb
  .search-form
  .form-group
  .search-icon {
  position: absolute;
  left: 10px;
  top: 9px;
  font-size: 13px;
}

.product-detail-sec.product-section
  .product-topbar
  .breadcrumb
  .search-form
  .form-group
  .search-class {
  padding-left: 5px;
}

.related-products {
  padding-top: 46px;
  padding-bottom: 60px;
}

.related-products .product-list {
  background: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.related-products .product-list .product-item {
  max-width: 25%;
  flex: 0 0 25%;
  margin-bottom: 0;
}
.related-products .product-list .product-item .product-hover-box {
  opacity: 1;
  visibility: visible;
  transform: translate(0);
}
.related-products .title h4 {
  margin: 0;
  color: #6699ff;
  font-size: 20px;
  font-weight: 900;
}
.related-products .title {
  text-align: center;
  margin-bottom: 35px;
}
.related-products .product-list .product-detail {
  opacity: 0.4;
}

.product-description {
  padding-top: 40px;
  border-top: 2px solid #f5f5f5;
}
.product-description .product-detail-box {
  max-width: 411px;
  flex: 0 0 411px;
}
.product-description .product-detail-box .product-detail-boxWrap {
  border-radius: 10px;
  background-color: #6699ff;
  padding: 48px 55px;
}
.product-description .product-detail-box .product-detail-boxWrap ul li {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
}
.product-description .desc-content {
  max-width: calc(100% - 411px);
  flex: 0 0 calc(100% - 411px);
  padding-right: 56px;
}
.product-description .desc-content .description h6 {
  margin: 0 0 30px;
  color: #6699ff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}
.product-description .desc-content .description {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 24px;
}
.product-description .desc-content .description:last-child {
  margin-bottom: 0;
}
.product-description
  .product-detail-box
  .product-detail-boxWrap
  ul
  li:last-child {
  margin-bottom: 0;
}

.single-product {
  padding-top: 40px;
}
.single-product-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
}
.single-product-wrap .single-product-slider-wrap {
  max-width: 630px;
  flex: 0 0 630px;
  display: flex;
  flex-wrap: wrap;
}
.single-product-slider-wrap .thumb-silder-wrap {
  max-width: 70px;
  flex: 0 0 70px;
}
.single-product-slider-wrap .thumb-silder-wrap .thumb-silder {
  width: 100%;
  height: 70px;
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  background-size: cover;
  margin-bottom: 30px;
}
.single-product-slider-wrap .thumb-silder-wrap .thumb-silder img {
  border-radius: 10px;
  height: 70px;
  width: 100%;
  object-fit: cover;
}
.single-product-slider-wrap .thumb-silder-wrap {
  margin-top: 10px;
}

.single-product-slider-wrap .product-slider-wrap {
  max-width: calc(100% - 70px);
  flex: 0 0 calc(100% - 70px);
  padding-left: 18px;
}
.single-product-slider-wrap .product-slider-inner {
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  height: 541px;
}
.single-product-slider-wrap .product-slider-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
}
.single-product-slider-wrap .product-slider-wrap .product-slider {
  height: 541px;
}
.single-product-slider-wrap .product-slider-wrap .slick-slide {
  /* padding: 10px; */
}
.single-product-slider-wrap .product-slider-inner .slick-list {
  border-radius: 10px;
}
.single-product-wrap .single-product-content-wrap {
  max-width: calc(100% - 630px);
  flex: 0 0 calc(100% - 630px);
  padding-left: 43px;
}

.size-qty {
  padding-top: 25px;
}
.content-wrap .title h4 {
  color: #6699ff;
  font-size: 20px;
  font-weight: 900;
  margin: 0 0 10px 0;
}
.content-wrap {
  position: relative;
  /* padding-bottom: 25px;
  border-bottom: 2px solid #f5f5f5; */
}
.content-wrap .sub-title {
  font-size: 18px;
}
.unit-price {
  color: #707070;
  font-size: 20px;
  font-weight: 900;
  margin-top: 10px;
}
.size-qty .form-group input[type="radio"] {
  opacity: 0;
  position: absolute;
}
.size-qty .form-group {
  position: relative;
}
.size-qty .form-group input[type="radio"] + label,
.preorder-table .order-history-table tr td.size label,
.preorder-table .cant label {
  color: #707070;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding: 0.5px 5px;
  width: 60px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.size-qty .form-group input[type="radio"]:checked + label {
  font-weight: 600;
  border: 2px solid #6f6f6f;
}
.size-qty-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.size-qty-wrap .product-size .title,
.product-qty .title {
  margin-bottom: 20px;
  color: #707070;
  font-size: 20px;
  text-align: center;
}
.size-qty-wrap .product-qty {
  padding-left: 26px;
}
.size-qty .form-group select {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  text-align: center;
  padding: 1px 0;
  margin-bottom: 15px;
}
.size-qty .form-group.selected select {
  border: 2px solid #6f6f6f;
  font-weight: 600;
}
.sub-total-box {
  width: 235px;
  border-radius: 13px;
  background-color: #f3f4ff;
  margin-left: 30px;
  padding: 70px 22px 56px;
  text-align: center;
}
.size-qty .product-size .form-group:before {
  content: "\f054";
  position: absolute;
  right: -17px;
  font-family: "FontAwesome";
  font-size: 12px;
  top: 7px;
}
.sub-total-box .title {
  color: #6699ff;
  font-size: 18px;
  font-weight: 900;
}
.sub-total-box .bill-amount {
  margin-top: 20px;
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 900;
}
.sub-total-box .submit-btn button {
  /* width: auto; */
  padding: 13px 30px;
  width: 190px;
}

/*---*/
.policy-disclaimer .title h4 {
  margin: 0 0 6px 0;
  color: #6699ff;
  font-size: 20px;
  font-weight: 900;
}
.policy-disclaimer .title .subtitle {
  color: #6699ff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}
.policy-disclaimer-sec {
  padding: 85px 0 75px;
}
.policy-disclaimer .contain {
  font-family: HelveticaNeueRegular;
  color: #707070;
  font-size: 20px;
}

/**--Archive Order--**/
.purchase-order-wrap {
  margin: 18px 0;
  border-radius: 13px;
  padding: 22px 18px 22px 42px;
}
.purchase-order-wrap .purchase-order-content {
  max-width: calc(100% - 257px);
  flex: 0 0 calc(100% - 257px);
}
.purchase-order-wrap .purchase-order-img {
  max-width: 257px;
  flex: 0 0 257px;
}
.purchase-order-wrap .purchase-order-img .img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 16px;
  background-color: #e21c2a;
}
.purchase-order-content .title h4 {
  margin: 0 0 28px 0;
  color: #6699ff;
  font-size: 25px;
  font-weight: 700;
}
.order-detail {
  color: #707070;
  font-family: Montserrat;
  font-size: 18px;
  line-height: 26px;
}
table.archive-order-table tr td:first-child {
  width: auto;
}

.order-history-table .article-detail {
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
}
.order-history-table .article-detail .image {
  box-shadow: 0 0 5px rgba(172, 172, 172, 0.36);
  border-radius: 10px;
  height: 129px;
  width: 129px;
  background-size: cover;
  background-position: center center;
}
.order-history-table .article-detail .article-detail-content {
  padding-left: 25px;
  max-width: 160px;
}
.order-history-table .article-detail .article-detail-content .title h4 {
  margin: 0 0 15px 0;
  color: #707070;
  font-family: Montserrat;
  font-size: 18px;
}
.order-history-table .article-detail .article-detail-content .subtitle {
  color: #6f6f6f;
  font-size: 16px;
  font-weight: 500;
}
.order-history-table tr th {
  color: #6699ff;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding: 22px 0;
}
.order-history-table-wrap {
  border-radius: 15px;
  background-color: #ffffff;
  padding-bottom: 88px;
}
.order-history.gray-bg {
  padding: 50px 0;
}
.order-history-table tr td {
  color: #707070;
  font-size: 20px;
  font-weight: 400;
  padding: 40px 20px;
  border-bottom: 2px solid #f5f5f5;
  position: relative;
}
.order-history-table tr td:not(:first-child) {
  text-align: center;
}
.order-history-table tr td:last-child,
table.order-history-table th img {
  cursor: pointer;
}
.order-history-table tr td.price {
  font-size: 16px;
  font-weight: 600;
}
.order-history-table tr td.subtotal {
  font-size: 18px;
  font-weight: 700;
}
.order-history-table-wrap .total-products {
  display: flex;
  justify-content: flex-end;
  padding-right: 90px;
  padding-top: 50px;
}
.order-history-table-wrap .total-products-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 328px);
  flex: calc(100% - 328px);
  position: relative;
  left: 328px;
}
.order-history-table-wrap .total-products-wrap .total-products {
  width: 100%;
  justify-content: space-evenly;
}
.order-history-table-wrap .total-products .products-unit {
  color: #707070;
  font-family: Montserrat;
  font-size: 20px;
}
.order-history-table-wrap .total-products .total {
  color: #6699ff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 900;
  padding-left: 30px;
}
.order-history-table-wrap .submit-btn {
  margin-top: 50px;
  padding-right: 90px;
}
.order-history-table-wrap .submit-btn button {
  /*width: 317px;*/
}
.order-history-table-wrap .submit-btn button.download-pdf-btn {
  margin-left: 27px;
}
.order-history-table-wrap .submit-btn button.download-excel-btn {
  opacity: 0.5;
}

/*-invoice-report-*/
.invoice-report .global-consumption-wrap {
  margin-bottom: 50px;
}
.billing-report .global-consumption-wrap {
  margin-bottom: 0;
}
.billing-report {
  padding: 50px 0;
}

/**--Search Page--**/
.search-result {
  padding: 50px 0 57px;
  text-align: center;
}
.search-result .content {
  color: #001689;
  font-size: 20px;
  font-weight: 800;
  max-width: 525px;
  margin: 0 auto;
  padding-bottom: 70px;
}
.search-result .suggested-products {
  color: #6699ff;
  font-size: 16px;
  font-weight: 900;
}

/*----*/
.preorder-sec {
  padding: 25px 0;
}
.preorder-sec .pre-order-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.preorder-sec .pre-order-wrap .title h4 {
  margin: 0;
  color: #707070;
  font-size: 25px;
  font-weight: 700;
}
.preorder-sec .pre-order-wrap .date {
  font-size: 20px;
}

/*----*/
.preorder-table .order-history-table tr td.article-detail-center {
  text-align: left;
}
.preorder-table .order-history-table tr th:first-child,
.order-history-table tr td:first-child {
  width: 80px;
  position: relative;
}
.preorder-table .order-history-table tr th.text-left {
  text-align: left;
}
.preorder-table .order-history-table tr th {
  padding: 20px 20px;
}
.preorder-table .order-history-table .article-detail {
  padding-left: 0;
}
.preorder-table .order-history-table tr th i {
  font-size: 27px;
}
.preorder-table .order-history-table tr td input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.preorder-table .order-history-table tr td input[type="checkbox"] + label {
  width: 16px;
  height: 16px;
  background: #fff;
  display: block;
  border-radius: 2px;
  border: 1px solid #707070;
  cursor: pointer;
}
.preorder-table
  .order-history-table
  tr
  td
  input[type="checkbox"]
  + label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  position: absolute;
  font-size: 12px;
  color: #6699ff;
  left: 22px;
  margin-top: 1px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.preorder-table
  .order-history-table
  tr
  td
  input[type="checkbox"]:checked
  + label:before {
  opacity: 1;
  visibility: visible;
}
.preorder-table .order-history-table tr td i.fa.fa-times-circle-o {
  color: #b1b1b1;
}
.preorder-table .order-history-table tr td.size .wrap {
  display: flex;
  align-items: center;
}
.preorder-table .order-history-table tr td.size .wrap label {
  margin-bottom: 0;
}
.preorder-table .order-history-table tr td.size .wrap img {
  margin-left: 3px;
  cursor: pointer;
}
.order-history-table tr td.size label {
  margin: 0;
}
.preorder-table .order-history-table tr td label {
  margin: 0;
}

.conditional-comment {
  margin-top: 35px;
}
.conditional-comment .title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 24px;
}
.conditional-comment .text-area-comment {
  position: relative;
  padding: 0 32px;
}
.conditional-comment .content-box {
  max-width: 1017px;
  padding: 20px 25px;
  border-radius: 9px;
  border: 1px solid #d0d0d0;
  margin: 0 auto;
  line-height: normal;
  letter-spacing: normal;
}

.process-order {
  position: relative;
  padding: 0 32px;
  margin-top: 50px;
}
.process-order .process-order-wrap {
  display: flex;
  flex-wrap: wrap;
}
.process-order .process-order-wrap .process-order-content {
  max-width: 338px;
  flex: 0 0 338px;
}
.process-order .process-order-wrap .process-order-content .content {
  border-radius: 21px;
  background-color: #f4f4f4;
  padding: 23px 28px;
  color: #6f6f6f;
  font-weight: 500;
  text-align: center;
}
.process-order .process-order-wrap .process-order-total {
  max-width: calc(100% - 338px);
  flex: 0 0 calc(100% - 338px);
  padding-left: 43px;
}
.process-order .process-order-wrap .process-order-total .total-products {
  padding: 0;
  justify-content: space-between;
}
.process-order .process-order-wrap .process-order-total .submit-btn {
  justify-content: space-between;
  padding: 0;
}
.delete-prepurchase {
  font-size: 18px;
  margin-top: 14px;
  color: #707070;
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
}

/*-Cancel Order-*/
.cancel-order-wrap {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 70px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-order {
  padding: 49px 0 59px;
  height: 54vh;
}
.cancel-order-wrap .box .title {
  color: #6699ff;
  font-size: 20px;
  font-weight: 900;
}
.cancel-order-wrap .box {
  max-width: 472px;
  margin: 0 auto;
  text-align: center;
}
.cancel-order-wrap .submit-btn {
  justify-content: center;
}

/*-Confirmation-*/
.shipment-details-sec {
  padding: 50px 0 120px;
}
.shipment-details-wrap {
  display: flex;
  flex-wrap: wrap;
}
.shipment-details-wrap .shipment-details {
  max-width: calc(100% - 452px);
  flex: 0 0 calc(100% - 452px);
  padding-right: 38px;
}
.shipment-details-wrap .preorder-summary {
  max-width: 452px;
  flex: 0 0 452px;
}
.shipment-details-wrap .shipment-details .shipment-details-box {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 35px 50px 50px;
  height: 100%;
  position: relative;
}
.shipment-details-wrap .shipment-details .shipment-details-box .title h4,
.shipment-details-wrap .preorder-summary .preorder-summary-box h4 {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 12px;
}
.shipment-details-wrap .shipment-details .shipment-details-box .sub-title {
  font-size: 18px;
}
.shipment-details-wrap .shipment-details .shipment-details-box .title {
  margin-bottom: 38px;
}
.shipping-location-form .form-group {
  position: relative;
}
.shipping-location-form .form-group input {
  width: 368px;
  height: 36px;
  border-radius: 29px;
  border: 1px solid #d6d6d6;
  margin-bottom: 0;
}
.shipping-location-form .form-group i {
  position: absolute;
  top: 8px;
  right: calc(100% - 358px);
  color: #6f6f6f;
}
.shipping-location-form .submit_btn button {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  border: 3px solid #6699ff;
  color: #6699ff;
  padding: revert;
}
.shipping-location-form {
  position: relative;
}
.shipping-location-form .submit_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% - 420px);
}
.shipping-location-form .submit_btn button i {
  position: relative;
  left: 1px;
}
.shipment-details-wrap .shipment-details .shipment-details-box .image {
  margin-top: 50px;
}

.shipment-details-wrap .preorder-summary .preorder-summary-box {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 35px 55px 35px 60px;
  height: 100%;
  position: relative;
}
.preorder-summary .order-summary {
  font-size: 18px;
  margin-top: 30px;
}
.preorder-summary .order-summary .subtotal,
.preorder-summary .order-summary .taxes {
  margin-bottom: 22px;
}
.preorder-summary .order-summary > div {
  display: flex;
}
.preorder-summary .order-summary > div .text-right {
  width: 112px;
  text-align: right;
}
.preorder-summary .order-summary > div .text-left {
  padding-left: 25px;
}
.preorder-summary .order-summary > div .total {
  font-weight: 900;
}
.preorder-summary .order-summary > div.total {
  font-weight: 900;
  color: #6699ff;
}
.preorder-table .order-history-table-wrap {
  padding-bottom: 50px;
}
.order-history.preorder-table {
  padding-bottom: 60px;
}
.finalize-order {
  margin-top: 44px;
}
.finalize-order .btn.blue-square-btn {
  width: 316px;
  display: block;
  text-align: center;
  font-weight: 700;
}

/*----*/
.confirmation-warning-sec .cancel-order-wrap {
  border-radius: 15px;
  background-color: #6699ff;
  color: #fff;
  padding: 50px 0;
}
.confirmation-warning-sec .cancel-order-wrap .title {
  color: #fff;
  margin-bottom: 30px;
  font-size: 45px;
  font-weight: 700;
}
.confirmation-warning-sec .cancel-order-wrap .content {
  font-size: 30px;
  font-weight: 500;
}
.confirmation-warning-sec .cancel-order-wrap .box {
  max-width: 756px;
}
.confirmation-warning-sec.cancel-order {
  height: auto;
  padding-bottom: 90px;
}

/**---Succesfull Information--**/
.shipment-details-wrap .preorder-summary.succesfull .preorder-summary-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  height: 350px;
}
.preorder-summary.succesfull .review-preorder {
  margin-top: 37px;
}
.review-preorder a {
  color: #707070;
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;
}

.popup-detail.article-detail {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  /* z-index: 1; */
  width: 545px;
  box-shadow: 0 0 6px rgba(172, 172, 172, 0.5);
  border-radius: 11px;
  padding: 20px 30px !important;
  align-items: center;
  opacity: 0;
  /* visibility: hidden; */
  transition: all 500ms ease-in-out;
}
.article-detail.popup-detail.show {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
.popup-detail.article-detail .size label,
.article-detail.popup-detail .qty label {
  font-size: 18px;
}
.popup-detail.article-detail .size select,
.article-detail.popup-detail .qty select {
  width: 80px;
  padding-left: 13px;
  margin-left: 0;
}
.popup-detail.article-detail .article-detail-content {
  max-width: 45%;
}
.popup-detail.article-detail .article-detail-content .size {
  margin-bottom: 20px;
}

.update-order .btn {
  font-weight: 600;
}

.show {
  display: block;
}
.gray-bg {
  background-color: #f5f5f5;
}
.categories_button,
.hide,
.close-btn {
  display: none;
}
/* 11-08-2021  */

.approve-button {
  display: flex;
  justify-content: flex-end;
}

.approve-button button {
  border-radius: 10px;
  /* background-color: #5aea49; */
  color: #fff;
  width: 226px;
  padding: 13px 10px;
  font-weight: 700;
  margin-top: 20px;
}

.approve-button button:last-child {
  margin-left: 27px;
}

.approve-button .green-btn {
  background-color: #4bd04b;
  border-color: #4bd04b;
}

.approve-button .red-btn {
  background-color: #de4141;
  border-color: #de4141;
}
.general-wrapper.orders-container .general-setting-wrap {
  padding: 50px 50px 20px 50px;
}
.orders-container {
  max-width: 100%;
}
.approve-button .red-btn:hover {
  background-color: #c72828;
}
.general-wrapper.orders-container .general-setting-wrap {
  padding: 50px 50px 20px 50px;
}

.order-date {
  display: flex;
  align-items: center;
}

.order-img {
  padding-left: 10px;
}

.order-img img {
  max-width: 65%;
}
.approve-button .green-btn:hover {
  background-color: #058a05 !important;
}

.orders-container .puchase-order {
  padding: 45px 65px;
  background-color: #f4f4f4;
  border-radius: 8px;
}
.orders-container .listing-order-section {
  background-color: #fff;
}
.orders-container .search-filter {
  padding-top: 0px;
}
.orders-container .admin-purchase-order .purchase-order-item {
  max-width: 484px;
  flex: 0 0 484px;
}

.cancel-btn .btn.blue-square-btn {
  background: transparent;
  border-color: red;
  color: red;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  position: relative;
  top: 0px;
}
.cancel-btn {
  position: absolute;
  right: -40px;
  top: 5px;
}

.add-field-wrap .btn.blue-square-btn {
  margin-top: 10px;
  width: auto;
}
/*23-08-2021*/
/* .select-overlap .css-b8ldur-Input input {
  width: 100% !important;
  margin-bottom: 0 !important;
  padding: 10px 20px 10px 0 !important;
} */

.select-overlap input {
  /* width: 100% !important; */
  padding: 0 20px 0 0 !important;
  margin-bottom: 0 !important;
  height: 30px;
}
/* RESPONSIVE CSS
-------------------------------------------------- */

/**  admin styles (added by sanket)  **/

/*-- Inner Banner --*/
.inner-banner-sec {
  padding: 30px 0;
}
.inner-banner-sec .inner-banner-wrap {
  position: relative;
  z-index: 1;
}
.inner-banner-sec .inner-banner {
  position: relative;
  width: 100%;
  height: 286px;
  background-size: cover;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.inner-banner-sec .inner-banner .inner-banner-title h4 {
  margin: 0;
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  max-width: 500px;
  padding-right: 0;
  text-align: center;
}
.inner-banner-sec .inner-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-image: linear-gradient(180deg, #001689 0%, #006dff 100%);
  opacity: 0.76;
  z-index: -1;
}

/**--Admin Search Panel--**/
.admin-company-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}
.admin-company-wrap .add-new-company {
  font-weight: 800;
  color: #6699ff;
  font-size: 18px;
}
.admin-company-wrap .add-new-company i {
  font-size: 12px;
}
.admin-company-wrap .search-company {
  position: relative;
}
.admin-company-wrap .search-company input {
  margin-bottom: 0;
  border-radius: 21px;
  border: 1px solid #d6d6d6;
  padding-left: 50px;
  width: 323px;
}
.admin-company-wrap .search-company i {
  position: absolute;
  left: 20px;
  color: #6f6f6f;
  font-size: 22px;
  top: 8px;
}
.admin-company-wrap .search-company input::placeholder {
  color: #b1b1b1;
  font-size: 18px;
  font-weight: 300;
}

/**--Company Grid--*/
.company-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -21px;
}
.company-grid .company-grid-item {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding: 0 21px;
  margin-bottom: 42px;
}
.item-box {
  box-shadow: 0 0 6px rgba(172, 172, 172, 0.5);
  border-radius: 11px;
  background-color: #ffffff;
}
.item-box .company-logo {
  text-align: center;
  padding: 30px;
}
.item-box .company-logo img {
  max-height: 95px;
  object-fit: contain;
  height: 95px;
}
.item-box .company-detail {
  border-top: 1px solid #d6d6d6;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-box .company-detail .company-name {
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
}
.item-box .company-detail .company-id {
  font-size: 18px;
  line-height: 24px;
}
.item-box .company-detail .go-icon i {
  font-size: 39px;
}

.mb-20 {
  margin-bottom: 20px;
}
.see-more a {
  color: #6699ff;
  font-weight: 900;
}
.text-center {
  text-align: center;
}
.pb-55 {
  padding-bottom: 55px;
}
.gray-bg {
  background-color: #f5f5f5;
}
.cursor-pointer {
  cursor: pointer;
}

/*--General Setting--*/
/*--General Setting--*/
.general-top-bar {
  background-color: #d9d9d9; /*padding:10px 0;*/
}
.general-top-bar .general-top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
}
.general-top-bar .general-top-wrap .title {
  color: #6699ff;
  font-size: 20px;
  font-weight: 900;
  text-decoration: underline;
}
.general-top-bar .general-top-wrap .order-id {
  font-size: 20px;
  font-weight: 900;
}
.return-order a.btn {
  width: 155px;
  display: block;
  text-align: center;
  font-weight: 700;
}

.main-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.sidebar {
  max-width: 325px;
  flex: 0 0 325px;
  background-color: #fafaf8;
  border-bottom-right-radius: 15px;
  padding: 50px 0;
  z-index: 1;
  max-height: calc(100vh - 298px);
  overflow: auto;
}
.sidebar .sidebar-logo img {
  width: 118px;
}
.sidebar .sidebar-logo {
  margin-bottom: 38px;
  padding-left: 100px;
}
.sidebar .sidebar-links {
  position: relative;
}
.sidebar .sidebar-links h4 {
  margin: 0 0 22px;
  font-size: 25px;
  font-weight: 700;
  padding-left: 100px;
  padding-right: 34px;
}
.sidebar .sidebar-links ul li {
  font-size: 20px;
  padding: 12px 34px 12px 100px;
  margin-bottom: 10px;
}
.sidebar .sidebar-links ul li.active {
  background-color: #ededed;
}
.sidebar .sidebar-links ul li a {
  color: #707070;
}

.general-wrapper {
  max-width: calc(100% - 325px);
  flex: 0 0 calc(100% - 325px);
  /* commenting next line to get bigger viewpoint while developing */
  /* height: calc(100vh - 313px); */
  height: calc(100vh - 299px);
  /* height: calc(140vh - 313px); */

  overflow-y: auto;
}
/* width */
.general-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
.general-wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

/* Handle */
.general-wrapper::-webkit-scrollbar-thumb {
  background: #001b90;
  border-radius: 2px;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

/* Handle on hover */
.general-wrapper::-webkit-scrollbar-thumb:hover {
  background: #cd5100;
}
.general-wrapper .general-setting-wrap {
  padding: 50px 70px 20px 50px;
  position: relative;
}
.general-wrapper .general-setting-wrap .title {
  font-weight: 800;
  color: #001b90;
  font-size: 20px;
  margin-bottom: 30px;
}
.general-wrapper .general-setting-wrap .return-link {
  margin-top: 10px;
}
.general-wrapper .general-setting-wrap .return-link i {
  margin-right: 10px;
}

.general-form .form-group label {
  margin-bottom: 10px;
  display: block;
}
.form-group label {
  display: block;
}
.general-form .wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.general-form .wrap .form-group {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 15px;
}
.general-form .wrap .drop-image {
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0 15px;
  margin-top: 26px;
}
.general-form .wrap .drop-image label {
  margin-bottom: 12px;
  display: block;
  font-size: 18px;
}

.general-complete-image {
  padding-top: 25px;
}
.general-wrapper .general-complete-image .title {
  color: #3c3c3c;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;
}
.general-complete-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.general-complete-grid .general-complete-item {
  max-width: 171px;
  flex: 0 0 171px;
  padding: 0 20px;
  margin-bottom: 40px;
}
.general-complete-grid .general-complete-item .image {
  width: 100%;
  height: 131px;
  background-size: cover;
  background-position: center center;
  background-color: #e0e0e0;
  border-radius: 19px;
}

.company-logo-wrap {
  display: flex;
}
.company-logo-wrap .company-logo {
  border-radius: 11px;
  background-color: #f5f5f5;
  opacity: 0.8;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  height: 90px;
  position: relative;
}
.company-logo-wrap .company-logo img {
  height: 56px;
}
.company-logo-wrap .company-logo img {
  /* opacity: 0.2; */
}
.company-logo-wrap .color-box {
  width: 147px;
  height: 44px;
  border-radius: 8px;
  background-color: #e0e0e0;
  margin-bottom: 3px;
}
.company-logo-wrap .color-code {
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  padding: 11px 10px;
  text-align: center;
}
.company-logo-wrap .distinctive {
  padding-left: 30px;
}
.company-logo-wrap .company-title {
  font-size: 18px;
  margin-bottom: 15px;
}
.company-logo .edit-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease-in-out;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
}
.company-logo:hover .edit-icon {
  opacity: 1;
  visibility: visible;
}
.company-logo .edit-icon img {
  height: auto;
  opacity: 1;
}

.catalog-banner h4,
.puchase-order-banner h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 15px 0;
}
.catalog-banner {
  padding-top: 60px;
}
.catalog-banner-wrap,
.puchase-banner-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.catalog-banner-wrap .catalog-banner-item,
.puchase-banner-wrap .catalog-banner-item {
  padding: 0 20px;
  max-width: 425px;
  flex: 0 0 425px;
  margin-bottom: 35px;
}
.catalog-banner-img {
  border-radius: 10px;
  height: 141px;
  background-size: cover;
  position: relative;
  background-position: center center;
  background-color: #eee;
}
.catalog-banner-img .catalog-banner-edit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease-in-out;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.catalog-banner-img:hover .catalog-banner-edit {
  opacity: 0.9;
  visibility: visible;
}
.catalog-banner-img .catalog-banner-edit .close-icon {
  position: absolute;
  top: -13px;
  right: -1px;
  height: 27px;
  width: 27px;
  border: 2px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.catalog-banner-img .catalog-banner-edit .close-icon i {
  position: relative;
  top: -2px;
}
.add-catalog {
  position: relative;
  display: flex;
  align-items: center;
  top: -20px;
}
.add-catalog .icon {
  color: #6699ff;
  width: 38px;
  height: 38px;
  border: 3px solid #6699ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
}
.puchase-banner-wrap .catalog-banner-img {
  height: 130px;
}
.choose-photo-popup {
  /* max-width: 820px; */
  box-shadow: 0 0 6px rgba(172, 172, 172, 0.5);
  border-radius: 20px;
  padding: 30px 40px 30px 60px;
  position: relative;
  max-height: 610px;
  overflow: auto;
}
.choose-photo-popup .title h4 {
  margin: 0;
  color: #707070;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  text-align: left;
}
.selection-photo-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.selection-photo-grid .selection-photo-item {
  max-width: calc(100% / 4);
  flex: 0 0 calc(100% / 4);
  margin-bottom: 30px;
  padding: 0 15px;
  text-align: center;
}
.form-group {
  position: relative;
}
.choose-photo-wrap
  .selection-photo-grid
  .selection-photo-item
  input[type="radio"] {
  width: auto;
}
.selection-photo-grid .selection-photo-item input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.selection-photo-grid .selection-photo-item input[type="radio"] + label {
  position: relative;
  cursor: pointer;
}
.selection-photo-grid .selection-photo-item input[type="radio"] + label:before {
  content: "";
  position: absolute;
  right: 4px;
  height: 15px;
  width: 15px;
  border: 1px solid #333;
  background: #fff;
}
.selection-photo-grid
  .selection-photo-item
  input[type="radio"]:checked
  + label:after {
  content: "\f00c";
  position: absolute;
  right: 5px;
  font-family: "FontAwesome";
  font-size: 13px;
  top: 2px;
  color: #002198;
}
/* .selection-photo-grid .selection-photo-item img {
  /*width:118px;height:118px;border-radius:19px;object-fit:cover;
} */
.selection-photo-grid .selection-photo-item .image {
  height: 140px;
  width: 140px;
  border-radius: 19px;
  object-fit: cover;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  -ms-border-radius: 19px;
  -o-border-radius: 19px;
}

.selection-photo-grid .selection-photo-item input[type="checkbox"] + label {
  float: right;
}
.selection-photo-grid
  .selection-photo-item
  input[type="checkbox"]
  + label::before {
  background: #fff;
  top: -5px;
}
.selection-photo-grid
  .selection-photo-item
  input[type="checkbox"]
  + label::after {
  top: -3px;
}

.choose-photo-wrap .link {
  display: flex;
  justify-content: flex-end;
}

/**--Users--**/
.add-new-user,
.return-home {
  font-weight: 800;
  color: #6699ff;
  font-size: 18px;
}
.add-new-user i {
  margin-left: 10px;
}
.return-home i {
  padding-right: 10px;
}
/* .users-page .users-table table tr th:nth-child(n+3),.users-page.users-table table tr td:nth-child(n+3){text-align:center;} */
.users-page .users-table {
  margin: 0;
}
.pb-15 {
  padding-bottom: 15px;
}
.users-table .form-group input[type="checkbox"] + label:before {
  top: -9px;
}
.users-page {
  /*overflow: auto;*/
  width: 100%;
  /* position: relative; */
  position: static;
}
.users-page .users-table {
  /*max-width:800px;overflow:auto;*/

  /*For Table Loader*/
  position: relative;
  min-height: 250px;
  width: 100%;
  overflow: auto;
}
.users-page .add-new-user img {
  top: 4px;
  position: relative;
  margin-left: 10px;
}
.users-page .search-result {
  padding-top: 80px;
}
.users-page .search-result .content {
  max-width: 385px;
}

/**--Branch Office New--**/
.add-new-branch {
  padding-top: 100px;
  text-align: center;
}
.general-wrapper .add-new-branch .title {
  color: #6699ff;
  cursor: pointer;
}

/*--User Page--*/
.user-page {
  max-width: 800px;
}
.user-form .col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}
.user-form .form-group label,
.branch-form .form-group label {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
}
.user-form .form-group select {
  padding: 10px 20px;
}
.user-form .form-group,
.branch-form .form-group {
  margin-bottom: 15px;
}
.user-image {
  padding-bottom: 40px;
}
.user-page .user-image {
  padding-top: 20px;
}
.users-table .form-group input[type="checkbox"]:checked + label:after {
  top: -8px;
  left: 1px;
}

.return-link a {
  color: #6699ff;
  font-weight: 900;
}
.valid-branches {
  padding-top: 45px;
}
.valid-branches h6 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 36px;
}
.valid-branches .col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}
.valid-branches label {
  margin-bottom: 25px;
}
.valid-branches .add-new-branch {
  padding-top: 30px;
  text-align: left;
}

/**--Branch Office--**/
.branch-form {
  max-width: 367px;
}
.branch-form textarea {
  font-size: 15px;
}

/**--Purchase Order--**/
.purchase-order-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.purchase-order-title .filter-wrap {
  display: flex;
  align-items: center;
}
.purchase-order-title .filter-wrap .content {
  font-size: 18px;
  margin-right: 10px;
}

.filter-form .filter-tab {
  width: 219px;
  border-radius: 16px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  padding: 4px 20px;
  position: relative;
  cursor: pointer;
  font-weight: 300;
  font-size: 18px;
  color: #b1b1b1;
}
.filter-form .filter-tab i {
  position: absolute;
  right: 6px;
  font-size: 13px;
  top: 6px;
}
.filter-listwrap {
  position: absolute;
  padding: 20px 20px;
  top: 40px;
  left: 0;
  z-index: 2;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease-in-out;
}
.filter-form {
  z-index: 2;
}
.filter-listwrap .filter-list {
  position: relative;
  margin-bottom: 4px;
}
.filter-listwrap .filter-list h6 {
  margin: 0;
  color: #707070;
  margin-bottom: 3px;
}
.filter-listwrap .filter-list:last-child {
  margin-bottom: 0;
}

.puchase-order {
  position: relative;
  padding-top: 80px;
}
.admin-purchase-order {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.admin-purchase-order .purchase-order-item {
  width: 100%;
  position: relative;
  padding: 0 15px;
  max-width: 414px;
  flex: 0 0 414px;
  /*-Added-*/
  margin-bottom: 30px;
}
.admin-purchase-order .order-box h5 {
  margin: 0;
}
.admin-purchase-order .item-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  height: 100%;
}
.admin-purchase-order .item-box .order-box {
  max-width: 116px;
  flex: 0 0 116px;
  background-color: #93b7ff;
  border-radius: 8px;
  color: #fff;
  padding: 25px 23px;
}
.admin-purchase-order .item-box .order-box h5 {
  color: #fff;
  font-size: 20px;
}
.admin-purchase-order .item-box .order-box .id {
  font-size: 10px;
}
.admin-purchase-order .item-box .order-detail-box {
  padding: 23px 14px 23px 34px;
  max-width: calc(100% - 116px);
  flex: 0 0 calc(100% - 116px);
}
.admin-purchase-order .item-box .order-detail-box .date {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
}
.see-order {
  color: #6699ff;
  font-weight: 900;
}
.see-order a {
  color: #6699ff;
}
.admin-purchase-order .item-box .order-detail-box .see-order {
  margin-top: 10px;
}

.no-puchase-order {
  padding-top: 100px;
  text-align: center;
}
.no-puchase-order .content {
  color: #6699ff;
  font-weight: 900;
  padding-top: 30px;
  max-width: 184px;
  margin: 0 auto;
}

/**----**/
.add-new-users {
  padding-top: 100px;
  text-align: center;
}
.add-new-users .content {
  color: #6699ff;
  font-weight: 900;
}
.add-user-form .icon {
  padding-bottom: 40px;
}
.add-user-form-wrap label {
  margin-bottom: 10px;
}
.add-user-form-wrap .form-group {
  margin-bottom: 20px;
}
.add-user-form-wrap {
  position: relative;
}
.user-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
.user-btn button {
  width: 155px;
}
.admin-company-wrap .return-link i {
  margin-right: 10px;
}
.admin-add-new-user .container {
  max-width: 800px;
}
.admin-add-new-user {
  padding-top: 30px;
}

/**--Pop-up--**/
.update-popup.show {
  display: flex;
}
.update-popup {
  position: absolute;
  top: -50px;
  width: 100%;
  display: none;
  justify-content: center;
}
.update-popup-wrap {
  box-shadow: 0 0 6px rgba(172, 172, 172, 0.5);
  border-radius: 11px;
  padding: 35px;
  text-align: center;
  background: #fff;
  width: 634px;
}
.update-popup .content {
  padding-bottom: 40px;
  color: #001689;
  font-size: 20px;
  font-weight: 700;
  max-width: 473px;
  margin: 0 auto;
}
.popup-buttons {
  display: flex;
  justify-content: space-between;
  max-width: 460px;
  margin: 0 auto;
}
.back-to-top a {
  font-weight: 900;
  color: #6699ff;
}
.back-to-user a {
  font-size: 18px;
  color: #b1b1b1;
  font-weight: 300;
}
.admin-add-new-user .admin-company-wrap .add-new-company {
  font-weight: 800;
  color: #001689;
  font-size: 20px;
}

/**--General Setting New--**/
.general-form-new .choose-photo-popup .title h4 {
  max-width: 410px;
  margin: 0 auto;
}

.general-form-new .choose-photo-wrap .link {
  justify-content: center;
}
.general-form-new .company-logo-wrap .company-logo {
  box-shadow: 0 0 6px rgba(172, 172, 172, 0.5);
  border-radius: 11px;
  background-color: #ffffff;
  opacity: 0.89;
  width: 207px;
}

.drop-box {
  border: 3px dashed #607d8b;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  color: #607d8b;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}
.invalid-feedback {
  margin: 5px 0px 30px 0px;
}
.users-table {
  margin-top: 25px;
}
.users-table table tr th {
  background-color: #f3f4ff;
  padding: 20px 20px;
  color: #6699ff;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
}
.users-table table tr td {
  padding: 30px 20px;
  font-size: 18px;
  border-bottom: 2px solid #f5f5f5;
}
.users-table table tr:last-child td {
  border: none;
}
.users-table table tr td .content-wrap {
  display: flex;
  align-items: center;
}
.users-table table tr td .content-wrap img {
  position: relative;
  left: -40px;
}
.users-table table tr td .content-wrap .name {
  font-size: 18px;
  font-weight: 700;
}
.users-table table tr td:last-child,
.users-table table tr th:last-child {
  text-align: center;
}
.edit {
  cursor: pointer;
}

/* for inner loader */

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
.stage:before {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.filter-contrast {
  filter: contrast(5);
  background-color: white;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #0f1689;
  color: #0f1689;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #0f1689;
  color: #0f1689;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #0f1689;
  color: #0f1689;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #0f1689;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

/* for pagination design */
ul.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.pagination li.page-no {
  padding: 0 20px;
}

ul.pagination li {
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
}

ul.pagination li.arrow-design {
  height: 20px;
  width: 20px;
  text-align: center;
}

ul.pagination li.arrow-design a {
  color: #0f1689;
  position: relative;
  text-align: center;
  font-size: 25px;
  line-height: 16px;
}

ul.pagination li:nth-child(2) a {
  color: #0f1689;
}
ul.pagination li.active a {
  background: #001689;
  border-radius: 50%;
  color: #ddd;
  padding: 4px 12px;
  cursor: pointer;
}

/* for slider for use banner */
.slides {
  position: relative;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}
.slick-next:before,
.slick-prev:before {
  font-family: "FontAwesome";
  color: #2b1a6b;
  background: none;
  font-size: 35px;
  transform: none;
  width: auto;
  height: auto;
  position: relative;
  line-height: 35px;
}
.slick-next:before {
  content: "\f105";
}
.slick-prev:before {
  content: "\f104";
}
.slick-next {
  right: 20px;
}
.slick-prev {
  left: 20px;
}
.slick-dots li {
  margin: 0;
}
.slick-dots {
  bottom: 10px;
}
.slick-dots li button:before {
  font-size: 10px;
}
/*27-07-2021*/
.mb-0 {
  margin-bottom: 0 !important;
}

.popup-content-center .selection-photo-grid h6 {
  width: 100%;
  text-align: center;
  margin: 25px 0;
}

.popup-content-center .link {
  justify-content: center;
}
.inner-banner-sec .inner-banner.no-overlay:before {
  content: none;
}
.inner-banner.right-title {
  justify-content: flex-end;
}

.inner-banner.right-title .inner-banner-title h4 {
  text-align: right;
  padding-right: 40px;
}

.min-h-100 {
  min-height: 100px;
}

.edit-product-popup .col-md-6 {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 10px;
}

.edit-product-popup .size,
.edit-product-popup .qty {
  max-width: 60px;
}
.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.css-26l3qy-menu {
  z-index: 2;
}
.search-filter-wrap .filter-form,
.filter-wrap .filter-form {
  width: 190px;
}
.edit-product-popup .image {
  text-align: center;
}
.cart-details {
  display: flex;
}
.cart-details .size {
  margin-right: 20px;
}
.cart-details .size h6,
.cart-details .qty h6 {
  margin: 10px 0px;
}
.choose-photo-popup .btn.blue-square-btn {
  padding: 7px 15px;
  font-size: 15px;
}
.qty input {
  padding-left: 10px;
}
.edit-product-popup .image img {
  width: 200px !important;
  border-radius: 10px;
}
.choose-photo-popup .btn.blue-square-btn:hover {
  text-decoration: none;
}
/* user slider */
.products .slick-prev,
.products .slick-next {
  background: transparent;
  color: #6699ff;
  top: calc(50% - 15px);
  transform: translateY(-50%);
}
.products .slick-prev {
  left: -40px;
}
.products .slick-next {
  right: -40px;
}
.no-data-text {
  font-weight: 900;
  font-size: 25px;
  padding-top: 30px;
}
/* auto height for no data table 17-08 */
.global-consumption-table-sec .global-consumption-table .nodata-table-height {
  height: 240px;
}
/* user catalogue detail page 17-08 */
.catalogue-search-btn {
  display: flex;
}
.catalogue-search-btn .form-group input {
  width: 200px;
  padding: 7px 11px 5px 30px;
}
.catalogue-search-btn .btn {
  padding: 2px 18px;
  font-size: 16px;
  line-height: 25px;
}
/* select option box z index */
.user-form .css-26l3qy-menu {
  position: relative !important;
  margin-bottom: 20px !important;
}
.select-zindex {
  z-index: 2;
}
/* user side slider hover */
.products .product-item .item-box:hover .product-hover-box {
  visibility: visible;
  opacity: 1;
}
.products .product-item .item-box {
  position: relative;
}
.gdi-solution-sec .gdi-solutionform h6 {
  margin-top: 0px !important;
  /* spinner css when data is nothing */
}
.spinner-position {
  position: relative;
  width: 100%;
  margin: 0;
  display: inline-block;
  height: calc(100vh - 350px);
}
/* 20-08 changes */
.order-loder-height .stage {
  min-height: 435px;
}
.select-overlap div[class$="menu"] {
  position: absolute !important;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 10;
}
.select-overlap {
  position: relative !important;
}
.select-overlap div[class$="MenuList"] {
  max-height: 140px;
}
.loder-height-product-detail .stage {
  top: 0;
}
/* 26-08-m-css */
.contact-sec .login-wrapper {
  height: 100vh;
}
/* 27-08 super admin loder box-shadow over lap footer */
.super-admin-footer-overlap .stage {
  top: 0;
}
/* 27-08 */
.product-searchbar a:hover {
  text-decoration: none;
}
/*02-09-2021*/
.productDetailTab {
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.productDetailTab ul {
  display: flex;
  width: 100%;
}

.productDetailTab ul li {
  padding: 10px;
  background: #d9d9d9;
  color: #707070;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-top: 2px solid transparent;
}

.productDetailTab ul li.active {
  background: transparent;
  border-top: 2px solid #001689;
  color: #001689;
}
.productDetailTab-content {
  padding: 40px;
}
.tabSubmit-btn {
  margin-top: 50px;
  display: flex;
}
.tabSubmit-btn button {
  width: auto;
}
.mt-20 {
  margin-top: 20px;
}

.content-detail .btn.blue-square-btn {
  color: #fff !important;
}
.content-detail .btn.blue-square-btn:hover {
  color: #001b90 !important;
}
.content-detail .content-detail-button {
  display: flex;
  justify-content: flex-end;
  /* width: 1; */
  margin: 10px;
}

/* 9-sept */

.thumb {
  align-items: center;
  display: flex;
}

.thumb .thumb-inner img {
  width: 30px;
}

.thumb .thumb-file-name {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}
.hover-icon .drop-box .hover-icon-image {
  visibility: hidden;
}
.hover-icon .drop-box:hover .hover-icon-image {
  visibility: visible;
}

.close-icon-solution.cursor-pointer {
  position: absolute;
  top: 17px;
  right: -11px;
  height: 27px;
  width: 27px;
  border: 2px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 16-09 */

.import-product-from-db .product {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}
.import-product-from-db img {
  width: 30px;
}
.import-product-from-db p {
  color: #6699ff;
  margin: 0;
}

/*-- Max Media Queries --*/
@media (min-width: 1900px) {
  .cancel-order {
    height: 55vh;
  }
}
@media (max-width: 1430px) {
  .productDetailTab ul li {
    font-size: 16px;
  }
  .productDetailTab .btn.blue-square-btn {
    font-size: 15px;
  }
}
@media (max-width: 1399px) {
  .single-product-content .submit-btn {
    justify-content: center;
  }
  /* admin user listing */
  .users-table table tr td {
    font-size: 16px;
  }
  /* admin orders */
  .admin-purchase-order .purchase-order-item {
    max-width: 384px;
    flex: 0 0 384px;
  }
  .puchase-order {
    padding-top: 60px;
  }
  .admin-purchase-order .item-box .order-detail-box {
    padding: 23px 14px 23px 13px;
  }
  /* 16-08 */
  .choose-photo-popup {
    max-height: 530px;
  }
  /* 26-08 */
  .load-more:focus-visible,
  .view-more:focus-visible {
    outline: none !important;
  }
  .load-more::selection,
  .view-more::selection {
    background-color: #fff !important;
  }
  /* product deail tab super admin 09-09 m-css */
  .productDetailTab ul li {
    font-size: 14px;
  }
  .general-wrapper .general-setting-wrap {
    padding: 50px 50px 20px 30px;
  }
  .productDetailTab .btn.blue-square-btn {
    font-size: 13px;
    padding: 10px 17px;
  }
  .productDetailTab .drop-box {
    height: 48px;
    font-size: 14px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .product-section .product-list-main .categories-list {
    max-height: 53vh;
    overflow-y: auto;
  }
}
@media (max-width: 1199px) {
  /* 10-08-m-css catalogue page */
  .container {
    max-width: 850px;
  }
  ul.mainmenu > li > a:link,
  ul.mainmenu > li > a:visited {
    font-size: 16px;
  }
  .right-menu ul .my-account-toggle {
    font-size: 14px;
    width: 150px;
    left: unset;
    right: 0;
  }
  ul.mainmenu li {
    padding-right: 20px;
  }
  .categories-list .form-group label {
    font-size: 13px;
  }
  .user-footer.footer .footer-links .quick-links a {
    font-size: 14px;
  }
  .user-footer.footer .footer-links .quick-links h4 {
    font-size: 16px;
  }
  .edit-product-popup {
    margin-bottom: 30px;
  }
  .white-btn {
    width: 100%;
    margin-bottom: 10px !important;
    margin: 0 auto;
    font-size: 11px;
  }
  .blue-btn {
    margin: 0 auto;
    font-size: 11px;
    width: 100%;
  }
  .blue-btn {
    padding: 5px;
  }
  .disclaimer ul.privacy li a {
    font-size: 15px;
  }
  /* contact page m-css 10-08 */
  .contact-sec.login-content-sec .login-content {
    padding: 119px 50px 50px 70px;
    max-width: 430px;
    flex: 0 0 430px;
  }
  .contact-sec.login-content-sec .login-form {
    padding: 100px 70px 70px 60px;
    max-width: calc(100% - 430px);
    flex-basis: calc(100% - 430px);
  }
  .login-content-sec .login-content .login-title-wrap .login-title h4 {
    font-size: 36px;
  }
  .contact-sec.login-content-sec
    .login-content
    .login-title-wrap
    .login-title
    .login-subtitle {
    font-size: 18px;
  }
  /* 11-08 m-css listing order */
  .inner-banner-sec .inner-banner .inner-banner-title h4 {
    font-size: 35px !important;
  }
  .listing-order-wrap {
    padding: 45px 35px;
  }
  .listing-order-wrap .listing-order-item {
    padding-right: 15px;
    padding-left: 15px;
  }
  .listing-order-wrap .item-code,
  .listing-order-wrap .listing-order-item-content h5 {
    font-size: 16px;
  }
  .listing-order-wrap .listing-order-item-content ul li,
  .listing-order-wrap .listing-order-item-content .view-ordr a,
  .listing-order-wrap .listing-order-item-content .sub-title h6 {
    font-size: 14px;
  }
  .listing-order-wrap .listing-order-item-content {
    padding: 15px 15px;
  }
  /* 11-08 m-css solutions */
  .option-box .option-box-inner h6 {
    font-size: 17px;
  }
  .option-box .option-box-inner p,
  .option-box .option-box-inner .note {
    font-size: 16px;
  }
  .gdi-option-wrap .gdi-option-grid .title h4 {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .gdi-option-wrap .gdi-option-grid {
    padding: 0 20px;
  }
  .fill-form .image img {
    width: 220px;
  }
  /* 11-08-m-css catalogue detail */
  .single-product-slider-wrap .product-slider-wrap .product-slider {
    width: 450px;
  }
  .single-product-wrap .single-product-slider-wrap {
    max-width: 540px;
    flex: 0 0 540px;
  }
  .single-product-wrap .single-product-content-wrap {
    max-width: calc(100% - 540px);
    flex-basis: calc(100% - 540px);
    padding-left: 35px;
  }
  .unit-price,
  .size-qty-wrap .product-size .title,
  .product-qty .title {
    font-size: 17px;
  }
  .sub-total-box {
    margin-left: 0;
    padding: 30px 22px 26px;
  }
  .size-qty .submit-btn {
    margin-top: 15px;
  }
  .product-description .row {
    justify-content: space-between;
  }
  .product-description .desc-content {
    max-width: calc(100% - 271px);
    flex-basis: calc(100% - 271px);
  }
  .product-description .product-detail-box {
    max-width: 271px;
    flex: 0 0 271px;
  }
  .product-description .product-detail-box .product-detail-boxWrap {
    padding: 38px 30px;
  }
  .product-description .desc-content .description p {
    font-size: 16px;
  }
  .product-description .product-detail-box .product-detail-boxWrap ul li {
    font-size: 15px;
  }
  .product-detail-sec.product-section {
    padding-bottom: 40px;
  }

  .product-topbar .product-searchbar,
  .product-topbar .breadcrumb {
    max-width: 100%;
    flex: 0 0 100%;
  }
  /* archive order listing */
  .purchase-order-content .title h4 {
    font-size: 22px;
  }
  .order-detail,
  .order-history-table tr td.price,
  .order-history-table tr td.subtotal,
  .order-history-table tr td,
  .order-history-table .article-detail .article-detail-content .subtitle {
    font-size: 15px;
  }
  .order-history-table .article-detail .article-detail-content {
    padding-left: 15px;
    font-size: 14px;
  }
  .order-history-table tr td {
    padding: 20px;
  }
  .order-history-table-wrap .total-products-wrap {
    left: 258px;
  }
  .order-history-table-wrap .total-products {
    padding-top: 30px;
    padding-right: 0;
  }
  .order-history-table-wrap .total-products .total {
    padding-left: 100px;
    font-size: 18px;
  }
  .order-history-table-wrap .total-products .products-unit {
    font-size: 18px;
  }
  .product-list .products .item-box .image {
    height: 188px;
  }
  .total-products-wrap .submit-btn button {
    font-size: 16px;
  }
  .product-topbar {
    display: block;
  }
  .product-detail-sec .product-topbar {
    display: flex;
  }
  .search-result .content {
    font-size: 16px;
  }
  /* gdi order listing m-css */
  .preorder-sec .pre-order-wrap .title h4 {
    font-size: 20px;
  }
  .preorder-sec .pre-order-wrap .date {
    font-size: 18px;
  }
  .conditional-comment .content-box {
    margin: 0 20px;
  }
  .process-order .process-order-wrap .process-order-total {
    max-width: calc(100% - 248px);
    flex-basis: calc(100% - 248px);
    padding-left: 30px;
  }
  .process-order .process-order-wrap .process-order-content {
    max-width: 248px;
    flex: 0 0 248px;
  }
  .process-order .process-order-wrap .process-order-content .content {
    padding: 20px 20px;
    font-size: 14px;
  }
  .order-history-table-wrap
    .process-order-wrap
    .submit-btn
    button.download-pdf-btn {
    margin-left: 0;
  }
  .order-history-table-wrap
    .process-order-wrap
    .submit-btn
    button.download-pdf-btn {
    margin-left: 0;
    width: 200px;
    font-size: 15px;
  }
  .process-order .process-order-wrap .process-order-total .submit-btn {
    margin-top: 20px;
  }
  .order-history-table-wrap .process-order .total-products .products-unit,
  .order-history-table-wrap .process-order .total-products .total {
    font-size: 15px;
  }
  .delete-prepurchase {
    font-size: 15px;
  }
  /* confirmation order m-css */
  .shipment-details-wrap .shipment-details {
    max-width: calc(100% - 352px);
    flex-basis: calc(100% - 352px);
  }
  .shipment-details-wrap .preorder-summary {
    max-width: 352px;
    flex: 0 0 352px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box,
  .shipment-details-wrap .preorder-summary .preorder-summary-box {
    padding: 30px 30px 30px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box .title h4,
  .shipment-details-wrap .preorder-summary .preorder-summary-box h4 {
    font-size: 21px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box .title {
    margin-bottom: 28px;
  }
  .finalize-order .btn.blue-square-btn {
    margin: 0 auto;
    width: 250px;
  }
  .shipping-location-form .submit_btn {
    right: calc(100% - 310px);
  }
  /* confirmation-warning */
  .preorder-sec .pre-order-wrap .title h4 {
    font-size: 20px;
  }
  .confirmation-warning-sec .cancel-order-wrap .content {
    font-size: 25px;
  }
  /* successfull-confirmation */
  .shipping-location-form .form-group i {
    right: calc(100% - 230px);
  }
  .shipping-location-form .form-group input {
    width: 248px;
  }
  .policy-disclaimer .contain {
    font-size: 18px;
  }
  .product-detail-sec .product-topbar .product-searchbar {
    max-width: calc(100% - 174px);
  }
  /* admin home page */
  .item-box .company-detail .company-name,
  .item-box .company-detail .company-id {
    font-size: 15px !important;
  }
  /* admin user listing */
  .general-wrapper .general-setting-wrap {
    padding: 40px 30px 20px 30px;
  }
  .users-table table tr td {
    font-size: 16px;
    padding: 20px 10px;
  }
  .users-table table tr th {
    font-size: 17px;
    padding: 20px 10px;
  }
  .user-form .form-group label,
  .branch-form .form-group label {
    font-size: 16px;
  }
  /* admin orders */
  .admin-purchase-order .purchase-order-item {
    max-width: 304px;
    flex: 0 0 304px;
  }
  .puchase-order {
    padding-top: 40px;
  }
  .purchase-order-title .filter-wrap {
    margin-top: 20px;
  }
  .admin-purchase-order .item-box .order-box h5 {
    font-size: 17px;
  }
  .admin-purchase-order .item-box .order-box {
    max-width: 86px;
    flex: 0 0 86px;
    padding: 15px;
  }
  .admin-purchase-order .item-box .order-detail-box .date {
    font-size: 16px;
  }
  .admin-purchase-order .item-box .order-detail-box {
    font-size: 14px;
    flex-basis: calc(100% - 86px);
    max-width: calc(100% - 86px);
  }
  .purchase-order-title {
    display: inline-block;
  }
  /* admin choose photo pop up */
  .selection-photo-grid .selection-photo-item .image {
    height: 110px;
  }
  .selection-photo-grid .selection-photo-item {
    max-width: calc(100% / 3);
    flex: 0 0 calc(100% / 3);
  }
  /* test admin use */
  .orders-container .admin-purchase-order .purchase-order-item {
    max-width: 354px;
    flex: 0 0 354px;
  }

  /*16-08-2021*/
  .sidebar .sidebar-links h4,
  .sidebar .sidebar-links ul li {
    padding-left: 50px;
    padding-right: 30px;
  }
}
@media (min-width: 1023px) and (max-width: 1199px) {
  /* product deail tab super admin 09-09 m-css */
  .productDetailTab ul li {
    font-size: 11px;
  }
  .productDetailTab .btn.blue-square-btn {
    font-size: 15px;
    padding: 10px 20px;
    margin-bottom: 20px;
    display: inline-block;
  }
  .productDetailTab-content {
    padding: 20px 30px;
  }
  .productDetailTab .drop-box {
    height: 45px;
    font-size: 13px;
  }
}
@media (max-width: 1023px) {
  /* 16-08-admin categories show hide */
  .sidebar.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    max-height: unset;
    transform: translateX(0);
    z-index: 3;
  }

  .sidebar {
    display: none;
    transform: translateX(-100%);
    transition: all 300ms ease-in-out;
  }

  .general-wrapper,
  .sidebar {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .sidebar.show .close-btn {
    position: absolute;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    display: block;
  }
  .sidebar.show .close-btn {
    top: 5px;
  }
  .general-top-bar .general-top-wrap .admin-sidebar-mobile-menu {
    display: block;
    position: absolute;
    top: -67px;
    right: 80px;
  }
  a.menulinks i {
    display: inline;
    position: relative;
    top: -7px;
    margin-left: 0;
    -webkit-transition-duration: 0s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-duration: 0s;
    -moz-transition-delay: 0.2s;
    transition-duration: 0s;
    transition-delay: 0.2s;
  }
  .menulinks i:before,
  .menulinks i:after {
    position: absolute;
    content: "";
    left: 6px;
  }
  .menulinks i,
  .menulinks i:before,
  .menulinks i:after {
    width: 30px;
    height: 3px;
    background-color: #000;
    display: inline-block;
  }
  .menulinks i:before {
    margin-top: -10px;
    -webkit-transition-property: margin, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0;
  }
  .menulinks i:after {
    margin-top: 10px;
    -webkit-transition-property: margin, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0;
  }
}
/* @media (min-width: 991px) {
  .login-content-sec .login-form {
    padding: 0;
  }
} */

@media (max-width: 991px) {
  .login-content-sec .login-content {
    /* height: 100vh; */
  }
  /* 10-08-m-css login page */
  .login-content-sec .login-content {
    padding: 15px 60px 80px 60px;
  }
  .login-content-sec .login-content .login-title-wrap .login-title h4 {
    font-size: 30px;
  }
  .login-content-sec
    .login-content
    .login-title-wrap
    .login-title
    .login-subtitle {
    font-size: 17px;
  }
  .login-content-sec .login-form {
    padding: 80px 50px 80px 0;
  }
  .login-content-sec .login-form .login-form-wrap .form-title h5 {
    margin: 0 0 45px 0;
  }
  .form-group label {
    font-size: 15px;
  }
  .login-content-sec .login-form .form-group input {
    padding: 10px 10px;
  }
  .login-form-wrap {
    width: 100%;
  }
  .login-content-sec .login-form .login-form-main {
    padding: 70px 35px 70px;
  }
  .login-content-sec .login-submit .blue-btn {
    width: 230px;
  }
  /* 10-08-m-css catalogue page */
  .container {
    max-width: 720px;
  }
  /* header */
  ul.mainmenu > li > a:link,
  ul.mainmenu > li > a:visited {
    font-size: 13px;
  }
  .right-menu ul li a .contact-btn {
    padding: 2px 10px;
    font-size: 12px;
  }
  ul.mainmenu li {
    padding-right: 14px;
  }
  .right-menu ul .my-account {
    margin-left: 10px;
  }
  .logo img {
    max-width: 150px;
  }
  ul.mainmenu > li.has-sub-menu:before {
    right: 0;
    top: 41%;
    font-size: 11px;
  }
  .product-topbar .product-searchbar .link .contact-btn {
    font-size: 15px;
  }
  .product-section .product-list-main .categories-wrap .main-title h4,
  .breadcrumb ul.breadcrumb-list li {
    font-size: 18px;
  }
  .product-section .product-list-main .categories h5 {
    font-size: 15px;
  }
  .product-section .product-list-main .categories-list .categories {
    padding-left: 30px;
    padding-right: 20px;
  }

  .product-topbar .breadcrumb {
    padding-left: 25px;
  }
  .product-topbar .product-searchbar {
    margin-left: 25px;
  }
  .product-section .product-list-main .categories-wrap .main-title h4 {
    padding: 0 30px;
  }
  .product-list .products .product-item {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 30px;
  }
  .product-list {
    margin-bottom: 30px;
    /* padding: 20px 20px; */
  }
  .footer-logo img {
    width: 180px;
  }
  .user-footer.footer .footer-links {
    padding-left: 10px;
  }
  .user-footer.footer .footer-links .quick-links a {
    font-size: 12px;
  }
  .user-footer.footer .footer-links .quick-links h4 {
    font-size: 14px;
  }
  /* contact page m-css 10-08 */
  .contact-sec.login-content-sec .login-content {
    padding: 119px 40px 50px 40px;
    max-width: 350px;
    flex: 0 0 350px;
  }
  .contact-sec.login-content-sec .login-form {
    padding: 100px 70px 70px 60px;
    max-width: calc(100% - 350px);
    flex-basis: calc(100% - 350px);
  }
  .login-content-sec .login-content .login-title-wrap .login-title h4 {
    font-size: 31px;
  }
  .contact-sec.login-content-sec
    .login-content
    .login-title-wrap
    .login-title
    .login-subtitle {
    font-size: 15px;
  }
  .search-filter-wrap .filter-form,
  .filter-wrap .filter-form {
    width: 28% !important;
  }
  /* 11-08 m-css solutions */
  .option-box .option-box-inner {
    padding: 20px;
  }
  .gdi-option-wrap .gdi-option-grid .title h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .option-box .option-box-inner h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .option-box .option-box-inner p,
  .option-box .option-box-inner .note {
    font-size: 14px;
  }
  .option-box .fill-form {
    padding: 15px;
  }
  .fill-form .image img {
    width: 200px;
  }
  .option-box .fill-form .link a {
    font-size: 21px;
  }
  .gdi-option-wrap {
    margin: 48px -40px 0 -40px;
  }
  .gdi-solutionform .form-group input,
  .gdi-solutionform .form-group textarea,
  .gdi-solutionform .form-group select {
    padding: 6px 10px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .gdi-solutionform .form-group input::placeholder,
  .gdi-solutionform .form-group textarea::placeholder {
    font-size: 15px;
  }
  .drop-box {
    height: 47px !important;
    font-size: 13px;
  }
  .submit-btn {
    margin-top: 15px;
  }
  .submit-btn button {
    width: 186px;
  }
  .gdi-solutionform .invalid-feedback {
    margin: 5px 0px 20px 0px;
  }
  /* 11-08 m-css consumption report */
  .global-consumption-sec .global-consumption-wrap .title h4,
  .global-consumption-sec .global-consumption-wrap .last-update-date,
  .global-consumption-table table tr th,
  .global-consumption-table table td {
    font-size: 14px;
  }
  .global-consumption-table table tr th {
    padding: 15px 10px;
  }
  .global-consumption-table-sec {
    padding: 56px 0 40px;
  }
  .download-excel {
    width: 257px;
  }
  .global-consumption-table-sec
    .global-consumption-table
    .global-consumption-tablewrap {
    height: 590px;
  }
  /* 11-08 m-css catalogue detail */
  .single-product-slider-wrap .product-slider-wrap .product-slider {
    width: 390px;
  }
  .single-product-wrap .single-product-slider-wrap {
    max-width: 480px;
    flex: 0 0 480px;
  }
  .single-product-wrap .single-product-content-wrap {
    max-width: calc(100% - 480px);
    flex-basis: calc(100% - 480px);
    padding-left: 25px;
  }
  .unit-price,
  .size-qty-wrap .product-size .title,
  .product-qty .title {
    font-size: 14px;
    margin-bottom: 0;
  }
  .size-qty {
    padding-top: 15px;
  }
  .unit-price,
  .size-qty-wrap .product-size .title,
  .product-qty .title {
    font-size: 14px;
    margin-bottom: 0;
  }
  .size-qty .form-group input[type="radio"] + label,
  .size-qty .form-group select {
    /* font-size: 14px; */
  }
  .size-qty .form-group input[type="radio"] + label {
    /* padding: 2px 5px; */
  }
  .sub-total-box .title,
  .sub-total-box .bill-amount {
    font-size: 16px;
  }
  .sub-total-box .bill-amount {
    margin-top: 10px;
  }
  .sub-total-box .submit-btn button {
    padding: 13px 10px;
  }
  .product-description .product-detail-box {
    max-width: 207px;
    flex: 0 0 207px;
  }
  .product-description .desc-content {
    max-width: calc(100% - 207px);
    flex-basis: calc(100% - 207px);
    padding-right: 20px;
  }
  .product-description .desc-content .description p {
    font-size: 15px;
  }
  .product-description .desc-content .description h6 {
    margin: 0 0 10px;
  }
  .product-description .product-detail-box .product-detail-boxWrap {
    padding: 28px 20px;
  }
  .product-description .product-detail-box .product-detail-boxWrap ul li {
    font-size: 14px;
  }
  .product-detail-sec.product-section {
    padding-bottom: 10px;
  }

  .sub-total-box .submit-btn button {
    padding: 9px 7px;
    font-size: 12px;
  }
  .order-history-table .article-detail .article-detail-content {
    padding-left: 0;
  }
  .order-history-table-wrap
    .process-order-wrap
    .submit-btn
    button.download-pdf-btn {
    margin-left: 0;
    width: 160px;
    font-size: 12px;
  }
  .order-history-table-wrap .process-order .total-products .products-unit,
  .order-history-table-wrap .process-order .total-products .total {
    font-size: 13px;
  }
  .process-order .process-order-wrap .process-order-content {
    max-width: 198px;
    flex: 0 0 198px;
  }
  .process-order .process-order-wrap .process-order-total {
    max-width: calc(100% - 198px);
    flex-basis: calc(100% - 198px);
  }

  /* confirmation order m-css */
  .shipment-details-wrap .shipment-details {
    max-width: calc(100% - 282px);
    flex-basis: calc(100% - 282px);
  }
  .shipment-details-wrap .preorder-summary {
    max-width: 282px;
    flex: 0 0 282px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box,
  .shipment-details-wrap .preorder-summary .preorder-summary-box {
    padding: 30px 20px 30px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box .title h4,
  .shipment-details-wrap .preorder-summary .preorder-summary-box h4 {
    font-size: 20px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box .sub-title,
  .preorder-summary .order-summary > div.total,
  .preorder-summary .order-summary > div .text-right,
  .preorder-summary .order-summary > div .text-left {
    font-size: 15px;
  }
  .shipping-location-form .css-g1d714-ValueContainer {
    height: 25px;
  }
  .shipping-location-form .css-1uccc91-singleValue {
    top: 56% !important;
  }
  .shipping-location-form .submit_btn {
    right: calc(100% - 290px);
  }
  .finalize-order {
    margin-top: 24px;
  }
  .finalize-order .btn.blue-square-btn {
    width: 200px;
  }
  /* confirmation-warning */
  .preorder-sec .pre-order-wrap .title h4,
  .preorder-sec .pre-order-wrap .date {
    font-size: 17px;
  }
  .confirmation-warning-sec .cancel-order-wrap .content {
    font-size: 25px;
  }
  .confirmation-warning-sec .cancel-order-wrap .content {
    font-size: 19px;
    max-width: 510px;
  }
  .confirmation-warning-sec .cancel-order-wrap .title {
    font-size: 35px;
  }
  .confirmation-warning-sec button.blue-btn.download-pdf-btn {
    font-size: 18px;
  }
  /* admin home page */
  .item-box .company-detail .company-name,
  .item-box .company-detail .company-id {
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .company-detail .go-icon img {
    width: 32px;
  }
  .item-box .company-logo {
    padding: 20px;
  }
  /* admin add user */
  .admin-company-wrap {
    padding-bottom: 20px;
  }
  .add-user-form .icon {
    padding-bottom: 20px;
  }
  .add-user-form-wrap .form-group input[type="text"],
  .add-user-form-wrap .form-group input[type="password"],
  .add-user-form-wrap .form-group input[type="tel"],
  .add-user-form-wrap .form-group input[type="email"],
  .add-user-form-wrap .form-group textarea {
    margin-bottom: 10px;
  }
  /* admin general setting */
  .sidebar {
    padding: 40px 0;
    max-width: 275px;
    flex: 0 0 275px;
  }
  .general-wrapper {
    max-width: calc(100% - 275px);
    flex-basis: calc(100% - 275px);
  }
  .sidebar .sidebar-logo,
  .sidebar .sidebar-links h4 {
    padding-left: 60px;
  }
  .sidebar .sidebar-links h4 {
    font-size: 23px;
  }
  .sidebar .sidebar-links ul li {
    font-size: 18px;
    padding: 9px 21px 9px 60px;
    margin-bottom: 5px;
  }
  .general-wrapper .general-setting-wrap {
    padding: 40px 40px 20px 40px;
  }
  .general-form .wrap .drop-image {
    margin-top: 15px;
  }
  .general-complete-grid .general-complete-item .image {
    height: 110px;
  }
  .general-complete-grid .general-complete-item {
    max-width: 141px;
    flex: 0 0 141px;
    padding: 0 10px;
    margin-bottom: 30px;
  }
  .general-top-bar .general-top-wrap .order-id,
  .general-top-bar .general-top-wrap .title {
    font-size: 16px;
  }
  .return-order .btn.blue-square-btn {
    padding: 6px 20px;
    font-size: 16px;
  }
  /* admin user listing */
  .admin-company-wrap .search-company input {
    width: 203px;
  }
  .add-new-user {
    font-size: 18px;
  }
  .general-wrapper .general-setting-wrap {
    padding: 40px 20px 20px 20px;
  }
  .users-table table tr td {
    font-size: 15px;
  }
  .general-setting-wrap .search-company .form-group input[type="text"] {
    padding: 10px 40px;
  }
  .general-wrapper .admin-company-wrap .search-company i {
    left: 11px;
    font-size: 16px;
    top: 11px;
  }
  .general-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  /*admin user update */
  .user-form .col-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  /* admin orders */
  .purchase-order-title .filter-form {
    width: 100% !important;
    font-size: 13px;
  }
  .purchase-order-title .filter-wrap {
    flex-wrap: wrap;
  }
  .purchase-order-title .filter-wrap .content {
    margin-bottom: 20px;
  }
  .admin-purchase-order .purchase-order-item {
    max-width: 384px;
    flex: 0 0 384px;
  }
  .selection-photo-grid .selection-photo-item .image {
    height: 80px;
  }
  .choose-photo-popup {
    padding: 30px 40px 30px 40px;
  }
  .admin-input-responsive .form-group input[type="text"],
  .admin-input-responsive .form-group input[type="password"],
  .admin-input-responsive .form-group input[type="tel"],
  .admin-input-responsive .form-group input[type="email"],
  .admin-input-responsive .form-group textarea {
    width: 350px;
  }
  .cancel-btn {
    width: calc(100% - 380px);
    right: 0;
  }
  /* test admin user */
  .orders-container .puchase-order {
    padding: 45px 35px;
  }
  .orders-container .admin-purchase-order .purchase-order-item {
    max-width: 324px;
    flex: 0 0 324px;
  }
  /* admin side */
  .approve-button button {
    width: 166px;
    font-size: 13px;
  }
  .order-history-table-wrap .approve-button button {
    width: 183px;
    font-size: 15px;
  }
  .order-history-table .submit-btn button {
    font-size: 13px !important;
    width: 160px;
  }
  /* user side cart products */
  .preorder-table table.order-history-table {
    overflow: auto;
    display: block;
  }
  /* admin sidebar */
  .general-top-bar .general-top-wrap .admin-sidebar-mobile-menu {
    top: -60px;
    right: 75px;
  }
  /* admin report table */
  .global-consumption-table-sec .global-consumption-table .nodata-table-height {
    height: 240px;
  }
  .products .slick-slider .white-btn,
  .products .slick-slider .blue-btn {
    width: 150px;
    font-size: 12px;
  }
  /* product inner page  */
  .sub-total-box .submit-btn button {
    width: 140px;
  }
  /* 26-08 */
  .approve-button button:last-child {
    margin-left: 33px;
  }
}
@media (min-width: 768px) {
  ul.mainmenu {
    display: block !important;
  }
  ul.mainmenu li:hover > ul {
    display: block;
  }
  ul.mainmenu ul li:hover ul {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .single-product-slider-wrap .product-slider-wrap .product-slider,
  .single-product-slider-wrap .product-slider-inner {
    height: 440px;
  }
}
@media (max-width: 767px) {
  /*Nav*/
  .menulinks {
    display: inline-block;
    top: 17px;
  }
  ul.mainmenu {
    text-align: left;
    position: absolute;
    /* top: 67px; */
    top: 58px;
    padding: 20px 0px;
    right: 0;
    width: 100%;
    background: #fff;
    display: none;
    z-index: 9999999;
  }
  ul.mainmenu > li {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
  }
  ul.mainmenu > li:first-child {
    border: none;
  }
  ul.mainmenu > li > a:link,
  ul.mainmenu > li > a:visited {
    padding: 10px 15px;
    font-size: 16px;
    float: left;
    width: 100%;
    border: none;
    text-align: left;
    color: #000;
  }
  ul.mainmenu > li > a:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .menulinks i {
    display: inline;
    position: relative;
    top: -7px;
    margin-left: 0;
    -webkit-transition-duration: 0s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-duration: 0s;
    -moz-transition-delay: 0.2s;
    transition-duration: 0s;
    transition-delay: 0.2s;
  }
  .menulinks i:before,
  .menulinks i:after {
    position: absolute;
    content: "";
    left: 0px;
  }
  .menulinks i,
  .menulinks i:before,
  .menulinks i:after {
    width: 30px;
    height: 3px;
    background-color: #000;
    display: inline-block;
  }
  .menulinks i:before {
    margin-top: -10px;
    -webkit-transition-property: margin, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0;
  }
  .menulinks i:after {
    margin-top: 10px;
    -webkit-transition-property: margin, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0;
  }
  .mobile-open .menulinks i {
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition-delay: 0.2s;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
  }
  .mobile-open .menulinks i:before {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: 0, 0.2s;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
  }
  .mobile-open .menulinks i:after {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition-delay: 0, 0.2s;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
  }

  ul.mainmenu > li > .current:after {
    display: none;
  }
  ul.mainmenu ul {
    position: relative;
    top: auto;
    left: auto;
    float: left;
    width: 100%;
  }
  ul.mainmenu ul li {
    position: relative;
  }
  ul.mainmenu li li a:link,
  ul.mainmenu li li a:visited {
    padding: 8px 15px 8px 25px;
    color: #000;
  }
  ul.mainmenu ul ul {
    position: relative;
    left: auto;
    top: auto;
    float: left;
    width: 100%;
  }
  ul.mainmenu ul ul li {
    padding-left: 10px;
  }

  a.child-triggerm {
    display: block !important;
    cursor: pointer;
    position: absolute !important;
    top: 0px;
    right: 0px;
    width: 50px !important;
    min-width: 50px !important;
    height: 38px !important;
    padding: 0 !important;
    border-left: 1px dotted rgba(255, 255, 255, 0.2);
  }
  a.child-triggerm:hover {
    text-decoration: none;
    color: #f00;
  }
  a.child-triggerm span {
    position: relative;
    top: 50%;
    margin: 0 auto !important;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }
  a.child-triggerm span:after {
    position: absolute;
    content: "";
  }
  a.child-triggerm span,
  a.child-triggerm span:after {
    width: 10px;
    height: 1px;
    background-color: #fff;
    display: block;
  }
  a.child-triggerm span:after {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }
  a.child-triggerm.child-open span:after {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }
  a.child-triggerm.child-open span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }

  a.child-triggerm:hover span,
  a.child-triggerm:hover span:after {
    background-color: #f00;
  }
  /* 10-08-m-css login page*/
  .login-content-sec .login-content,
  .login-content-sec .login-form {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .login-content-sec .login-form {
    padding: 50px;
  }
  .login-content-sec .login-content {
    height: auto;
    text-align: center;
  }
  .login-content-sec .login-content .login-title-wrap {
    padding-top: 20px;
    max-width: 326px;
    padding-bottom: 70px;
    margin: 0 auto;
  }
  .login-content-sec .login-content .login-title-wrap .login-title h4 {
    font-size: 36px;
  }
  .login-content-sec .login-content {
    padding: 15px 60px 50px 60px;
  }
  .login-content-sec .login-form .login-form-main {
    padding: 50px 40px 50px;
    text-align: center;
    border-radius: 20px;
  }
  .social-icons {
    position: relative;
    justify-content: center;
  }
  .login-content-sec
    .login-form
    .login-form-wrap
    .login-form-inner
    .forgot-pwd {
    position: relative;
  }
  .login-content-sec .login-submit {
    margin-top: 50px;
  }
  .login-content-sec .login-form .form-group input {
    width: 60%;
  }
  .login-content-sec .login-form .login-form-wrap .form-title h5 {
    margin: 0px 0 35px 0;
  }
  /* 10-08-m-css catalouge page*/
  .container {
    max-width: 100%;
  }
  .logo img {
    max-width: 100%;
  }
  .right-menu ul li a .contact-btn {
    padding: 5px 18px;
    font-size: 16px;
  }
  .nav-menu .right-menu {
    padding-right: 70px;
  }
  .product-detail .product-price {
    font-size: 14px;
    text-align: center;
  }
  .user-footer.footer .footer-logo {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }
  .user-footer.footer .footer-links {
    flex-basis: calc(100% - 0px);
    max-width: calc(100% - 0px);
    margin: 30px 0px;
  }
  .right-menu ul .my-account i {
    margin-left: 4px;
    font-size: 13px;
  }
  .banner-section .welcome-box-wrap,
  .product-section .product-list-main {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .banner-section .banner-slider-wrap,
  .product-section .product-list-wrap {
    max-width: calc(100% - 0px);
    flex-basis: calc(100% - 0px);
  }
  .banner-section .slick-prev {
    left: 10px;
  }
  .banner-section .slick-next {
    right: 10px;
  }
  .product-topbar .breadcrumb {
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .product-topbar .product-searchbar .link .contact-btn {
    font-size: 12px;
    padding: 5px 11px;
  }
  .product-list .products .product-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .white-btn,
  .blue-btn {
    width: 190px;
    font-size: 14px;
  }
  .product-list .products .item-box {
    width: 100%;
    padding: 0 20px;
    justify-content: center;
  }
  .product-hover-box .hover-buttons {
    display: flex;
    flex-direction: column;
  }
  .product-hover-box .desc {
    max-width: 200px;
    margin: 0 auto;
    margin-top: 8px;
  }
  .col.welcome-box-wrap {
    margin-bottom: 30px;
  }

  .footer-logo img {
    width: 220px;
  }
  .categories_button a {
    background-image: linear-gradient(to right, #001689 0%, #006dff 100%);
    color: #fff;
    padding: 5px 18px !important;
    border-radius: 19px;
    font-size: 18px !important;
    font-weight: 400;
    border: 2px solid #006dff;
    font-size: 12px;
    padding: 5px 10px;
  }
  .categories_button {
    display: block;
    margin-right: 20px;
  }

  .product-section .product-list-main .categories-wrap {
    display: none;
    position: fixed;
    z-index: 6;
    top: 0;
    width: 100%;
    background: #fffffff2;
    height: 100%;
    left: 0;
    margin: 0;
  }

  .close-btn {
    position: absolute;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    display: block;
  }
  .product-section .product-list-main .categories-wrap.show {
    display: block;
  }
  .product-section .product-list-main .categories-list .categories {
    padding: 0px 40px;
  }

  .product-section .product-list-main .categories h5 {
    font-size: 20px;
  }
  .categories-list .form-group label {
    font-size: 15px;
  }

  .product-section .product-list-main .categories-wrap .main-title h4 {
    font-size: 22px;
  }
  ul.mainmenu.show {
    display: block;
  }
  ul.sub-menu.show {
    display: block;
  }
  .header-main .menu-section {
    position: unset;
    width: 100%;
  }
  ul.mainmenu {
    box-shadow: 1px 3px 8px #aaaaaa;
  }
  .product-searchbar .link {
    display: none;
  }
  ul.mainmenu ul {
    box-shadow: none;
    padding: 10px 35px;
  }
  ul.mainmenu ul li a {
    font-size: 15px !important;
    padding-left: 20px !important;
  }
  ul.mainmenu > li.has-sub-menu a:before {
    left: 100px;
    top: 24%;
    display: inline-block;
    width: auto;
    color: #707070;
  }
  /* contact page m-css */
  .contact-sec.login-content-sec .login-content {
    padding: 59px 40px 10px 40px;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .contact-sec.login-content-sec .login-form {
    padding: 60px 70px 50px 60px;
    max-width: 100%;
    flex-basis: 100%;
  }
  .login-content-sec .login-form .contact-form .form-group input {
    width: 100%;
  }
  /* 11-08 m-css listing order */
  .listing-order-wrap .listing-order-item-content ul li,
  .listing-order-wrap .listing-order-item-content .view-ordr a,
  .listing-order-wrap .listing-order-item-content .sub-title h6 {
    font-size: 13px;
  }
  .listing-order-wrap .item-code,
  .listing-order-wrap .listing-order-item-content h5 {
    font-size: 15px;
  }
  .search-filter-wrap .filter-form,
  .filter-wrap .filter-form {
    width: 30% !important;
  }
  .inner-banner-sec .inner-banner .inner-banner-title h4 {
    font-size: 30px !important;
    max-width: 426px !important;
  }
  .listing-order-wrap .listing-order-item {
    padding-right: 18px;
    padding-left: 18px;
    max-width: 50%;
    flex: 0 0 50%;
  }
  .listing-order-wrap .item-code,
  .listing-order-wrap .listing-order-item-content h5 {
    font-size: 16px;
  }
  .listing-order-wrap .listing-order-item-content ul li,
  .listing-order-wrap .listing-order-item-content .view-ordr a,
  .listing-order-wrap .listing-order-item-content .sub-title h6 {
    font-size: 15px;
  }
  /* 11-08 m-css solutions */
  .gdi-solutionwrap .title .subtitle {
    font-size: 15px;
  }
  .gdi-solutionwrap .title h4 {
    font-size: 22px;
    margin-top: 20px;
  }
  .gdi-option-wrap .gdi-option-grid {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .gdi-option-wrap:before {
    display: none;
  }
  .gdi-option-wrap .gdi-option-grid:first-child {
    margin-bottom: 50px;
  }
  .gdi-option-wrap .gdi-option-grid .title h4 {
    font-size: 25px;
  }
  .option-box .option-box-inner h6 {
    font-size: 18px;
  }
  .option-box .option-box-inner p,
  .option-box .option-box-inner .note {
    font-size: unset;
  }
  .option-box .fill-form {
    padding: 30px;
  }
  .fill-form .image img {
    width: 100%;
  }
  .global-consumption-sec .global-consumption-wrap .title h4,
  .global-consumption-sec .global-consumption-wrap .last-update-date,
  .global-consumption-table table tr th,
  .global-consumption-table table td {
    font-size: 14px;
  }
  .global-consumption-sec .global-consumption-wrap {
    display: block;
  }
  .title {
    margin-bottom: 10px;
  }
  .global-consumption-sec .global-consumption-wrap .title h4,
  .global-consumption-sec .global-consumption-wrap .last-update-date {
    font-size: 16px;
  }
  .download-excelwrap {
    padding-top: 30px;
  }
  /* 10-08-m-css catalogue detail*/
  .single-product-slider-wrap .thumb-silder-wrap .thumb-silder {
    width: 70px;
    margin-top: 20px !important;
    margin: 0 auto;
  }
  .single-product-wrap .single-product-slider-wrap {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .single-product-slider-wrap .product-slider-wrap .product-slider {
    width: auto;
  }
  .single-product-wrap .single-product-content-wrap {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .sub-total-box {
    margin-left: 30px;
  }
  .product-detail-boxWrap ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .product-description .product-detail-box .product-detail-boxWrap ul li,
  .product-description
    .product-detail-box
    .product-detail-boxWrap
    ul
    li:last-child {
    margin-bottom: 10px;
  }
  .product-description .product-detail-box .product-detail-boxWrap {
    padding: 28px 20px 18px;
  }
  .single-product-slider-wrap .thumb-silder-wrap {
    max-width: 150px;
    flex: 0 0 150px;
    order: 1;
    margin-top: 30px;
    margin: 0 auto;
  }
  .single-product-slider-wrap .thumb-silder-wrap .thumb-silder img {
    height: 70px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .single-product-slider-wrap .product-slider-wrap {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
  }
  .product-detail-sec .contact-btn {
    width: 100px;
  }
  .product-description .product-detail-box .product-detail-boxWrap {
    padding: 28px 20px 18px;
  }
  .product-detail-sec.product-section .product-topbar {
    padding-bottom: 0;
  }
  .product-topbar .product-searchbar {
    margin-left: 0px;
  }
  .breadcrumb ul.breadcrumb-list li {
    font-size: 18px;
  }
  .single-product-wrap .single-product-content-wrap {
    padding-left: 0px;
  }
  .unit-price,
  .size-qty-wrap .product-size .title,
  .product-qty .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .product-description .desc-content {
    max-width: 100%;
    flex: 0 0 100%;
    order: 2;
    margin-top: 30px;
  }
  .product-description .product-detail-box {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .product-list .products .item-box .image {
    height: 248px;
  }
  .order-history-table .article-detail {
    padding-left: 0px;
  }
  .order-history-table .article-detail .article-detail-content .subtitle {
    font-size: 13px;
  }
  .order-history-table-wrap .total-products-wrap {
    left: 0;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .order-history-table-wrap .submit-btn {
    padding-right: 0;
    margin-top: 40px;
    text-align: center;
  }
  .order-history-table-wrap .total-products-wrap {
    justify-content: center;
  }
  .purchase-order-wrap .purchase-order-content {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  .purchase-order-wrap .purchase-order-img {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .purchase-order-wrap .purchase-order-img .img-box {
    background-color: transparent !important;
    padding: 20px 0px;
    float: left;
  }
  .global-consumption-table-sec
    .global-consumption-table
    .global-consumption-tablewrap::-webkit-scrollbar {
    width: 10px;
  }
  .pre-order-wrap .title {
    margin-bottom: 0;
  }
  /* cart products m-css */
  .process-order .process-order-wrap .process-order-content,
  .process-order .process-order-wrap .process-order-total {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
  }
  .process-order .process-order-wrap .process-order-content {
    margin-bottom: 30px;
  }
  .process-order .process-order-wrap .process-order-content .content {
    font-size: 17px;
  }
  .order-history-table-wrap .process-order .total-products .products-unit,
  .order-history-table-wrap .process-order .total-products .total {
    font-size: 16px;
  }
  .order-history-table-wrap
    .process-order-wrap
    .submit-btn
    button.download-pdf-btn {
    width: 190px;
    font-size: 14px;
  }

  /* confirmation order m-css */
  .shipment-details-wrap .preorder-summary,
  .shipment-details-wrap .shipment-details {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .shipment-details-wrap .shipment-details {
    margin-bottom: 50px;
    padding-right: 0;
  }
  .shipping-location-form .submit_btn {
    right: calc(100% - 290px);
  }
  .shipping-location-form .form-group {
    width: 240px !important;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box .sub-title,
  .preorder-summary .order-summary > div.total,
  .preorder-summary .order-summary > div .text-right,
  .preorder-summary .order-summary > div .text-left {
    text-align: left;
  }
  .preorder-summary .order-summary > div .text-left {
    padding-left: 0;
  }
  .finalize-order {
    float: left;
    margin-bottom: 40px;
  }
  .preorder-sec .pre-order-wrap .date,
  .preorder-sec .pre-order-wrap .title h4 {
    font-size: 15px !important;
  }
  /* successfull-confirmation/ */
  .preorder-summary.succesfull .review-preorder {
    text-align: left;
    margin-top: 17px;
  }
  .shipment-details-wrap .preorder-summary.succesfull .preorder-summary-box {
    height: 210px;
  }
  .policy-disclaimer .contain {
    font-size: 15px;
    line-height: 25px;
  }
  .policy-disclaimer-sec {
    padding: 45px 0 45px;
  }
  /* user order listing */
  .responsive-order-listing tr th {
    display: none;
  }
  .responsive-order-listing tr td:before {
    content: attr(data-th);
    font-weight: bold;
    width: 50%;
    display: inline-block;
    text-align: left;
  }
  .responsive-order-listing tr td {
    display: block;
  }
  .responsive-order-listing.order-history-table tr td:not(:first-child) {
    text-align: left;
  }
  .responsive-order-listing.order-history-table .article-detail {
    margin-top: 20px;
    align-items: center;
  }
  .responsive-order-listing.order-history-table
    .article-detail
    .article-detail-content {
    padding-left: 20px;
  }
  .responsive-order-listing tr {
    padding-bottom: 30px;
    display: inline-block;
    width: 100%;
  }
  /* admin-home page */
  .company-grid .company-grid-item {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .admin-company-wrap .search-company input {
    width: 263px;
  }
  .admin-company-wrap .search-company input::placeholder {
    font-size: 15px;
  }
  .item-box .company-detail .company-name,
  .item-box .company-detail .company-id {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  /* admin general setting */
  .general-wrapper,
  .sidebar {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .sidebar {
    padding: 30px 0;
  }
  .general-complete-grid .general-complete-item .image {
    height: 130px;
  }
  .general-complete-grid .general-complete-item {
    max-width: 182px;
    flex: 0 0 182px;
  }
  /* admin orders */
  .purchase-order-title .filter-form {
    width: 100% !important;
    font-size: 15px;
  }
  /* admin pop up */
  #photo-modal .modal-content {
    width: 72%;
  }
  .selection-photo-grid .selection-photo-item .image {
    height: 90px;
  }
  .admin-footer.footer {
    position: relative;
  }
  /* .general-top-bar .general-top-wrap a {
    display: none;
  } */
  .general-top-bar .order-id {
    display: none;
  }
  .general-top-bar .general-top-wrap {
    flex-wrap: wrap;
  }
  .general-top-bar {
    padding: 10px 0px;
  }
  .company-grid {
    margin: 0 -10px;
  }
  /* test user admin */
  .orders-container .admin-purchase-order .purchase-order-item {
    max-width: 285px;
    flex: 0 0 285px;
  }
  /* admin order */
  .admin-purchase-order .purchase-order-item {
    max-width: 314px;
    flex: 0 0 384px;
  }
  /* user pop up */
  #edit-modal .modal-content {
    width: 80%;
  }
  .admin-footer-margin {
    margin-bottom: 0;
  }
  /* user side menu close button css */
  .menulinks.close i {
    height: 0px;
  }
  .menulinks.close i:before {
    margin-top: 0;
    transform: rotate(45deg);
  }
  .menulinks.close i:after {
    margin-top: 0;
    transform: rotate(-45deg);
  }
  .responsive-order-listing.order-history-table tr td:last-child {
    border-bottom: none;
  }
  /* user slider */
  .related-products .slick-prev {
    left: 10px;
  }
  .related-products .slick-next {
    right: 10px;
  }
  /* admin side bar */
  .general-top-bar .general-top-wrap .admin-sidebar-mobile-menu {
    top: -60px;
  }
  /* admin report table */
  .global-consumption-table-sec .global-consumption-table .nodata-table-height {
    height: 240px;
  }
  /*  admin brand orders  */
  .orders-container .admin-purchase-order {
    /* m-css for center in responsive */
    justify-content: center;
  }
  /* user slider hover effect */
  .products .slick-slider .white-btn,
  .products .slick-slider .blue-btn {
    width: 150px;
    font-size: 14px;
  }
  /* product inner page  */
  .sub-total-box .submit-btn button {
    width: 160px;
  }

  .single-product-slider-wrap .product-slider-inner,
  .single-product-slider-wrap .product-slider-wrap .product-slider {
    height: 350px;
  }
  /* 26-08-chnages */
  .product-section .product-list .products .item-box {
    margin: 0;
    padding: 0;
  }
  .mobile-loder-space .stage {
    margin-top: 10px;
  }
  .size-qty-wrap {
    justify-content: center;
  }
  .single-product-content .unit-price {
    text-align: center;
  }
  /* 27-08 */
  .product-list {
    padding: 20px 20px;
  }
  /* product deail tab super admin 09-09 m-css */
  .productDetailTab ul {
    display: inline-block;
  }
  .productDetailTab ul li {
    font-size: 16px;
  }
  .productDetailTab-content {
    padding: 20px;
  }
  .productDetailTab .btn.blue-square-btn {
    font-size: 13px;
    padding: 8px 16px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .thumb .thumb-file-name {
    font-size: 12px;
  }

  .thumb .thumb-inner img {
    width: 20px;
  }

  .import-product-from-db p {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  /*404 page*/
  .search-form label {
    width: 100%;
  }
  .search-form .screen-reader-text {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
  }
  .search-form .search-field {
    width: 100%;
  }
  .search-form .search-submit {
    width: 100%;
  }
  .admin-footer .footer-logo img {
    width: 130px;
  }

  .admin-footer .footer-logo {
    padding: 10px 0;
  }
  .orders-container .admin-purchase-order .purchase-order-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
  /* admin order */
  .admin-purchase-order .purchase-order-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 601px) and (max-width: 768px) {
  .products .slick-slider .white-btn,
  .products .slick-slider .blue-btn {
    width: 100px;
    font-size: 10px;
  }
  .products .slick-slider .product-hover-box .desc {
    font-size: 10px;
  }
}
@media (max-width: 576px) {
  /* 10-08-m-css login page*/
  .login-content-sec .login-form .form-group input {
    width: 80%;
  }
  .login-content-sec .login-form {
    padding: 30px;
  }
  .search-form .form-group input {
    /* width: 100%; */
  }
  .product-list .products .item-box {
    padding: 0 5px;
  }
  .white-btn {
    margin-bottom: 5px !important;
  }
  .product-hover-box .desc {
    font-size: 10px;
  }
  .product-topbar .product-searchbar .link .contact-btn {
    font-size: 10px;
    padding: 5px 15px;
  }
  .product-topbar .product-searchbar {
    justify-content: space-between;
  }
  .user-footer.footer .footer-links {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 30px 0px;
    flex-wrap: wrap;
  }
  .user-footer.footer .footer-links .quick-links {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 40px;
    text-align: center;
  }
  .user-footer.footer .footer-links .quick-links h4 {
    font-size: 18px;
  }
  .user-footer.footer .footer-links .quick-links a {
    font-size: 16px;
  }
  .right-menu ul li a .contact-btn {
    /* display: none; */
  }
  /* 11-08 m-css listing order */
  .inner-banner-sec .inner-banner .inner-banner-title h4 {
    font-size: 23px !important;
  }
  .search-filter-wrap .filter-form,
  .filter-wrap .filter-form {
    width: 40% !important;
    font-size: 14px;
  }
  .search-filter .search-filter-wrap .search-filter-title {
    font-size: 15px;
  }
  .listing-order-wrap .listing-order-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
  /* 11-08 m-css solutions */
  .gdi-solutionwrap {
    padding: 30px 45px 23px;
  }
  .gdi-option-wrap {
    margin: 18px -40px 0 -40px;
  }
  .fill-form .image img {
    width: 190px;
  }
  .option-box .fill-form {
    padding: 10px 20px;
  }

  .global-consumption-sec {
    padding: 40px 0 42px;
  }
  .global-consumption-table-sec {
    padding: 36px 0 30px;
  }
  .download-excel {
    width: 197px;
    font-size: 15px;
  }
  .login-content-sec .login-submit .blue-btn {
    width: 160px;
    font-size: 16px;
    height: 39px;
  }
  .sub-total-box {
    margin-left: 10px;
  }

  /* 12-08 view orders */
  .total-products-wrap .submit-btn button {
    font-size: 13px;
    width: 170px;
    padding: 10px 5px;
  }
  .order-history-table-wrap .approve-button button {
    width: 165px;
    font-size: 13px;
    padding: 10px 10px;
  }
  .order-history-table-wrap .total-products .total {
    padding-left: 20px;
    font-size: 15px;
  }
  .order-history-table-wrap .total-products .products-unit {
    font-size: 15px;
  }

  .purchase-order-content .title h4 {
    font-size: 20px;
  }
  .breadcrumb ul.breadcrumb-list li {
    font-size: 14px;
  }
  .preorder-table .order-history-table {
    width: 100%;
    overflow: auto;
    display: block;
  }
  /* invoice report */
  .global-consumption-table-sec .global-consumption-table {
    background-color: transparent;
    padding-right: 0;
  }
  .global-consumption-table-sec
    .global-consumption-table
    .global-consumption-tablewrap {
    background: #fff;
  }
  .global-consumption-table-sec
    .global-consumption-table
    .global-consumption-tablewrap::-webkit-scrollbar {
    width: 5px;
  }
  /* order cart m-css */
  .process-order .process-order-wrap .process-order-content .content {
    font-size: 15px;
  }
  .process-order {
    margin-top: 30px;
    padding: 0 10px;
  }
  .order-history-table-wrap .process-order .total-products .products-unit,
  .order-history-table-wrap .process-order .total-products .total {
    font-size: 13px;
  }
  .order-history-table-wrap
    .process-order-wrap
    .submit-btn
    button.download-pdf-btn {
    width: 150px;
    font-size: 11px;
  }
  .preorder-sec .pre-order-wrap .date,
  .preorder-sec .pre-order-wrap .title h4 {
    font-size: 13px;
  }
  /* .cancel-order-wrap .box .title{
    font-size: 16px;
    max-width: 300px;
  } */
  .confirmation-warning-sec .cancel-order-wrap .content {
    font-size: 15px;
    max-width: 310px;
  }
  .confirmation-warning-sec button.blue-btn.download-pdf-btn {
    font-size: 15px;
    width: 150px;
  }
  .shipment-details-wrap .shipment-details .shipment-details-box .title h4,
  .shipment-details-wrap .preorder-summary .preorder-summary-box h4 {
    font-size: 16px;
  }
  .cancel-order-wrap .box .title {
    font-size: 18px;
  }
  /* admin-home page */
  .company-grid .company-grid-item {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 22px;
  }
  .company-detail .go-icon img {
    width: 40px;
  }
  .admin-company-wrap {
    flex-wrap: wrap;
  }
  .admin-company-wrap .search-company {
    margin-top: 10px;
  }
  /* admin general setting */
  .general-top-bar .general-top-wrap .order-id,
  .general-top-bar .general-top-wrap .title {
    font-size: 13px;
    margin-bottom: 0;
  }
  .return-order .btn.blue-square-btn {
    padding: 6px 14px;
    font-size: 14px;
  }
  .sidebar .sidebar-logo {
    margin-bottom: 20px;
  }
  .sidebar .sidebar-links ul li {
    font: 16px;
  }
  .sidebar .sidebar-links h4 {
    font-size: 20px;
    margin: 0 0 15px;
  }
  .general-wrapper .general-setting-wrap {
    padding: 30px 20px 10px 20px;
  }
  .general-setting-wrap .form-group label {
    font-size: 14px;
  }
  .general-setting-wrap .form-group input[type="text"],
  .general-setting-wrap .form-group input[type="password"],
  .general-setting-wrap .form-group input[type="tel"],
  .general-setting-wrap .form-group input[type="email"],
  .general-setting-wrap .form-group textarea {
    padding: 5px 10px;
    font-size: 14px;
  }
  .general-complete-grid .general-complete-item .image {
    height: 170px;
  }
  .general-complete-grid .general-complete-item {
    max-width: 202px;
    flex: 0 0 202px;
  }
  /* user update */
  .general-setting-wrap .user-form .form-group label {
    font-size: 17px;
  }
  .general-setting-wrap .user-form .form-group input[type="text"],
  .general-setting-wrap .user-form .form-group input[type="password"],
  .general-setting-wrap .user-form .form-group input[type="tel"],
  .general-setting-wrap .user-form .form-group input[type="email"],
  .general-setting-wrap .user-form .form-group textarea {
    padding: 10px 10px;
  }
  .user-form .form-group,
  .branch-form .form-group {
    margin-bottom: 0px;
  }
  .general-wrapper {
    height: auto;
  }
  /* admin orders */
  .purchase-order-title .filter-form {
    width: 100% !important;
    font-size: 15px;
  }
  .purchase-order-wrap {
    padding: 22px 18px 22px 18px;
  }
  .choose-photo-popup {
    padding: 30px 20px 30px 20px;
  }
  .selection-photo-grid .selection-photo-item {
    margin-bottom: 20px;
    padding: 0 10px;
  }
  .selection-photo-grid .selection-photo-item .image {
    height: 110px;
  }
  .admin-input-responsive .form-group input[type="text"],
  .admin-input-responsive .form-group input[type="password"],
  .admin-input-responsive .form-group input[type="tel"],
  .admin-input-responsive .form-group input[type="email"],
  .admin-input-responsive .form-group textarea {
    width: 310px;
  }
  .cancel-btn {
    width: calc(100% - 340px);
    right: 0;
  }
  .no-data-text {
    font-size: 18px;
  }
  /* admin side bar */
  .general-top-bar .general-top-wrap .admin-sidebar-mobile-menu {
    top: -46px;
  }
  /* admin table 17-08 */
  .global-consumption-table-sec .global-consumption-table .nodata-table-height {
    height: 210px;
  }
  /* user slider hover effect */
  .products .slick-slider .white-btn,
  .products .slick-slider .blue-btn {
    width: 130px;
    font-size: 12px;
  }
  /* m-css 26-08 */
  .catalog-banner-wrap .catalog-banner-item,
  .puchase-banner-wrap .catalog-banner-item {
    max-width: 385px;
    flex: 0 0 385px;
  }
  .add-catalog {
    left: -10px;
  }
  .logo img {
    max-width: 100px !important;
  }
  .header-section .right-menu ul li a .contact-btn {
    font-size: 10px !important;
    padding: 2px 10px !important;
  }
  .nav-menu .right-menu {
    padding-right: 40px;
  }
  .menulinks i:before {
    margin-top: -7px;
  }
  .menulinks i:after {
    margin-top: 7px;
  }
  .menulinks i,
  .menulinks i:before,
  .menulinks i:after {
    width: 26px;
    height: 2px;
  }
  .menulinks {
    top: 10px;
  }
  .login-content-sec .login-form .login-form-main {
    padding: 50px 20px 50px;
  }
  .product-detail-sec .cart {
    padding-bottom: 20px;
  }
  /* 27-08 super admin view order loder issue in mobile */
  .mobile-loder-spacing .stage {
    position: relative;
    margin-top: 50px;
  }
  .superadmin-adduser .form-group.select-overlap {
    margin-bottom: 20px;
  }
  /* product deail tab super admin 09-09 m-css */
  .productDetailTab ul li {
    font-size: 14px;
  }
  .productDetailTab .content-detail .content-detail-button {
    display: inline-block;
    margin: 0;
  }
  .productDetailTab-content .content-detail-button > div {
    margin: 0 !important;
  }
  .productDetailTab .btn.blue-square-btn {
    font-size: 11px;
    padding: 8px 11px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .productDetailTab-content form {
    margin-top: 20px;
  }
  .productDetailTab .drop-box {
    font-size: 11px;
  }
  .productDetailTab .drop-box {
    height: 31px !important;
  }
  .drop-image label {
    font-size: 14px;
  }
  .tabSubmit-btn {
    margin-top: 20px;
  }
}
@media (max-width: 430px) {
  .search-form .form-group input {
    /* width: 160px; */
    width: 140px;
  }
  .product-topbar .product-searchbar .link .contact-btn {
    font-size: 10px;
    padding: 5px 6px;
  }
  .logo img {
    max-width: 130px;
  }
  .categories_button a,
  .right-menu ul li a .contact-btn {
    /* font-size: 14px !important; */
    font-size: 11px !important;
  }
  .categories_button a {
    padding: 5px 12px !important;
  }
  .user-footer.footer .footer-links .quick-links h4 {
    font-size: 16px;
  }
  .user-footer.footer .footer-links .quick-links a {
    font-size: 14px;
  }
  .disclaimer ul.privacy li a {
    font-size: 12px;
  }
  .selection-photo-grid .selection-photo-item .image {
    height: 70px;
  }
  .general-complete-grid .general-complete-item {
    max-width: 198px;
    flex: 0 0 198px;
  }
  .order-history-table-wrap
    .process-order-wrap
    .submit-btn
    button.download-pdf-btn {
    width: 130px;
    font-size: 9px;
  }
  .total-products-wrap .submit-btn button {
    font-size: 10px;
    width: 120px;
  }
  .order-history-table-wrap .total-products .products-unit,
  .order-history-table-wrap .total-products .total {
    font-size: 10px;
  }
  .search-filter .search-filter-wrap .search-filter-title {
    font-size: 13px;
  }
  .search-filter-wrap .filter-form,
  .filter-wrap .filter-form {
    font-size: 12px;
  }
  .search-filter-wrap .css-g1d714-ValueContainer {
    padding: 2px 3px;
  }
  .general-complete-grid .general-complete-item {
    max-width: 160px;
    flex: 0 0 160px;
  }
  .general-complete-grid .general-complete-item .image {
    height: 140px;
  }
  .catalog-banner-wrap .catalog-banner-item,
  .puchase-banner-wrap .catalog-banner-item {
    max-width: 282px;
    flex: 0 0 282px;
  }
  .general-setting-wrap.mobile-loder-spacing.general-form-input
    .form-group
    label {
    font-size: 15px;
    max-width: 100px;
  }
  .general-setting-wrap.mobile-loder-spacing .form-group label {
    max-width: 100%;
  }
  .general-setting-wrap.superadmin-adduser .form-group label {
    max-width: 100%;
  }
  .company-logo-wrap .company-title {
    font-size: 14px;
  }
  .add-new-user,
  .return-home {
    font-size: 18px;
  }
  .admin-company-wrap {
    display: block;
  }
  .add-new-user {
    margin-bottom: 20px;
  }
  .general-top-bar .general-top-wrap .admin-sidebar-mobile-menu {
    top: -47px;
  }
  /* 26-08-m-css */
  .company-logo-wrap .company-logo {
    padding: 10px 20px;
  }
  .company-logo-wrap .company-logo img {
    height: 46px;
  }
  .add-catalog {
    left: -10px;
  }
  .login-content-sec .login-form .form-group input {
    width: 100%;
  }
  .order-history-table-wrap .approve-button button {
    width: 122px;
    font-size: 10px;
    padding: 10px 10px;
  }
  .order-history-table-wrap .approve-button button:last-child {
    margin-left: 28px;
  }
  /* 27-08 */
  .choose-photo-popup .title h4 {
    font-size: 15px;
  }
}

/*22-09-2021*/
.related-products .product-item {
  padding: 0 10px;
}

.order-pago {
  width:400px;

}

/* 03-09-24 */
.fmx-mercadopago {

  width: 50%;

  display: flexbox;

  margin-top: 35px;
}
