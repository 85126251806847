@import './theme/css/font-awesome.min.css';
@import './theme/css/style.css';

.invalid-feedback {
  color: red;
}

.button-loader {
  margin-right: 5px;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.d-none {
  display: none;
}

.Toastify__close-button {
  width: auto !important;
}
